import { TFunction, useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ScheduleOpeningStatus } from '@hotelplan/components.common.help-overlay';
import type { OpeningHours } from '@hotelplan/graphql.types';
import {
  getCurrentOpeningHours,
  isDateShiftIncluded,
  mapOpeningHoursToSchedules,
} from '@hotelplan/libs.schedule';

interface IScheduleProps {
  openingHours: OpeningHours[];
  timezone: string;
  showStatusIcon?: boolean;
  className?: string;
  mapCustomOpeningHoursToSchedules?: (
    t: TFunction,
    openingHours: OpeningHours[]
  ) => string[];
}

const UPDATING_OPEN_STATUS_INTERVAL = 5 * 1000;

const ScheduleWrapper = styled.div<{ opened: boolean }>(
  ({ theme }) => theme.helpOverlay.schedule,
  ({ opened, theme: { colors } }) => ({
    '> p': {
      color: opened ? colors.success : colors.primary,
    },
  })
);

const Schedule: React.FC<IScheduleProps> = ({
  openingHours,
  timezone,
  showStatusIcon,
  className,
  mapCustomOpeningHoursToSchedules,
}) => {
  const [t] = useTranslation('agency');

  const [opened, setOpened] = useState(() => {
    const currentDate = new Date();
    return openingHours.length
      ? isDateShiftIncluded(
          getCurrentOpeningHours(openingHours, currentDate).shifts,
          timezone,
          currentDate
        )
      : false;
  });

  const updateOpenedState = useCallback(() => {
    const currentDate = new Date();
    const currentHours = getCurrentOpeningHours(openingHours, currentDate);
    setOpened(isDateShiftIncluded(currentHours.shifts, timezone, currentDate));
  }, [openingHours, timezone]);

  useEffect(
    function updateOpenedStateEffect() {
      const intervalID = setInterval(
        updateOpenedState,
        UPDATING_OPEN_STATUS_INTERVAL
      );

      return function cleanupInterval() {
        clearInterval(intervalID);
      };
    },
    [updateOpenedState]
  );

  const mapFunction = mapCustomOpeningHoursToSchedules
    ? mapCustomOpeningHoursToSchedules
    : mapOpeningHoursToSchedules;

  if (!openingHours.length) return null;

  return (
    <ScheduleWrapper opened={opened} className={className}>
      <ScheduleOpeningStatus opened={opened} showStatusIcon={showStatusIcon} />
      <div>{t('openingHours.title')} </div>
      <div data-id="help-overlay-opening-hours">
        {mapFunction(t, openingHours).map((item, index) => {
          return (
            <div key={index} data-id="help-overlay-opening-hours-day">
              {item}
            </div>
          );
        })}
      </div>
    </ScheduleWrapper>
  );
};

export default Schedule;
