import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Image } from '@hotelplan/components.common.image';

export const GridRecommendationBlock = styled.div<{
  fontColor?: string;
  bgColor?: string;
}>(({ theme: { media }, fontColor, bgColor }) => ({
  color: fontColor,
  backgroundColor: bgColor,
  paddingTop: '50px',
  paddingBottom: '50px',
  scrollMarginTop: '100px',
  [media.tablet]: {
    paddingTop: '80px',
    paddingBottom: '80px',
    margin: 'auto',
  },
}));

export const GridRecommendationItems = styled.div(
  sx2CssThemeFn({
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr 1fr 1fr'],
    gap: [2, '20px'],
    mt: ['25px', 6],
    alignItems: 'start',

    '.geo-item-wrapper': {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      '&:focus-visible': {
        outline: 'none',
        '.geo-item': {
          outline: '5px solid',
          outlineColor: `redHover`,
        },
        img: {
          transform: 'scale(1.1)',
          transition: 'all 0.5s ease',
        },
      },
      '&:hover': {
        img: {
          transform: 'scale(1.1)',
          transition: 'all 0.5s ease',
        },
        '.geo-title': {
          '.icon': {
            marginRight: 0,
            transition: 'all 0.5s ease',
          },
        },
      },
    },
  })
);

export const GridRecommendationSectionMainTitle = styled.h3(
  ({ theme: { media, space, fontSizes } }) => ({
    fontSize: '46px',
    fontWeight: '800',
    lineHeight: '60px',
    textAlign: 'center',
    maxWidth: '818px',
    margin: '0 auto',
    marginBottom: '60px',
    [media.mobile]: {
      fontSize: fontSizes[7],
      lineHeight: '42px',
      marginBottom: space[6],
    },
  })
);

export const GridRecommendationItem = styled.div(({ theme: { colors } }) => ({
  position: 'relative',
  overflow: 'hidden',
  height: 'auto',
}));

export const GridRecommendationMobileButtonItems = styled.div(
  ({ theme: { colors, fontSizes } }) => ({
    background: colors.paperGrey,
    borderRadius: '5px',
    fontSize: fontSizes[1],
    lineHeight: '22px',
    fontWeight: '500',
    color: colors.black,
    padding: '10px',

    '&:hover, &:focus': {
      background: colors.redHover,
      color: colors.white,
      '.icon': {
        fill: colors.white,
      },
    },
  })
);

export const GridRecommendationItemImage = styled(Image)({
  display: 'block',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'all 0.5s ease',
  maxHeight: '274px',
});

export const GridRecommendationTitle = styled.h4(
  ({ theme: { colors, media, fontSizes } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    marginBottom: '6px',
    fontSize: fontSizes[1],
    lineHeight: '22px',
    fontWeight: 'normal',
    [media.mobile]: {
      marginTop: 0,
      marginBottom: 0,
    },
    span: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': `2`,
      lineClamp: `2`,
      '-webkit-box-orient': 'vertical',
    },
    '.icon': {
      fill: colors.primary,
      marginRight: '10px',
      transition: 'all 0.5s ease',
      flex: 'none',
    },
  })
);
