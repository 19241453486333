import React, { ReactElement, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ThirdHeading } from 'theme/headings';
import { Icon } from '@hotelplan/components.common.icon';
import { Link } from '@hotelplan/components.common.link';
import { cutTextByLinesCount } from '@hotelplan/components.common.mixins';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import BookmarkBlock from 'components/domain/bookmark/BookmarkBlock';
import { DisruptersSection } from 'components/domain/disrupters/Disrupters';
import { TeaserImageCard } from 'components/domain/teaser-image-card';
import { BookmarkComponentType } from 'components/domain/tracking/bookmarkTracking';
import { StaticProductRecommendationItemFragment } from 'graphql/recommendations/StaticProductRecommendation.generated';
import { CardItemBreadcrumbs } from './Recommendations.common';

const ProductRecommendationWrapper = styled.div(
  ({ theme: { text, colors, space, media, fontSizes } }) => ({
    '.image-card': {
      marginBottom: space[3],
    },
    '.route-list, .teaser-text': {
      marginBottom: space[2],
    },
    '.route-list': cutTextByLinesCount(1),
    '.teaser-text': {
      ...text.copyText,
      ...cutTextByLinesCount(3),
      maxHeight: '80px',
    },
    '.heading-wrap': {
      marginBottom: '12px',
      '.icon': {
        color: colors.primary,
        marginLeft: space[2],
        width: '23px',
        verticalAlign: 'text-bottom',
      },
    },
    '.heading-title': {
      display: 'inline',
      fontSize: '22px',
      fontWeight: '900',
      lineHeight: 1.25,
      p: {
        display: 'inline',
      },
      [media.mobile]: {
        fontSize: fontSizes[4],
        lineHeight: '30px',
      },
    },
    '.tagline-text': {
      paddingTop: space[3],
      [media.tablet]: {
        paddingTop: space[4],
      },
    },
    'a:hover': {
      color: 'inherit',
    },
    '.breadcrumb-wrap': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      '.features-list': {
        width: '85%',
        flexGrow: 1,
      },
      '.icon': {
        transition: 'all 0.5s ease',
        color: colors.primary,
        marginRight: '10px',
        marginBottom: '3px',
      },
    },
  })
);

const TeaserImageCardWrapper = styled.div({
  position: 'relative',
  '> button': {
    position: 'absolute',
    right: '16px',
    top: '16px',
    cursor: 'pointer',
    zIndex: 5,
    '.wishlist-icon': {
      width: '26px',
      height: '26px',
      color: 'white',
    },
    '.button-text': {
      display: 'none',
    },
  },
});

export function ProductRecommendation(
  props: Omit<StaticProductRecommendationItemFragment, '__typename'> & {
    className?: string;
    lazy?: boolean;
    getImageHeight?: (height: number) => number;
  }
): ReactElement {
  const {
    name,
    link,
    features,
    disrupters,
    image,
    lazy,
    className,
    bookmarked,
    getImageHeight,
  } = props;
  const imageRef = useRef<HTMLDivElement>(null);
  const { h } = useElementSize(imageRef);

  useEffect(() => {
    getImageHeight && getImageHeight(h);
  }, [h, getImageHeight]);

  return (
    <ProductRecommendationWrapper className={className} data-id="product">
      <TeaserImageCardWrapper ref={imageRef}>
        <TeaserImageCard
          className="image-card"
          link={link}
          image={image}
          lazy={lazy}
        >
          <DisruptersSection
            disrupters={disrupters}
            className="product-recommendation-disrupter"
          />
        </TeaserImageCard>
        <BookmarkBlock
          pageType={link.targetPageType}
          objectId={link.objectId}
          active={bookmarked}
          trackingComponent={BookmarkComponentType.RECOMMENDER}
        />
      </TeaserImageCardWrapper>
      <RouterLink {...getTargetPageData(link)} passHref>
        <Link>
          <div className="heading-wrap">
            <ThirdHeading className="heading-title">{name}</ThirdHeading>
          </div>
          <div className="breadcrumb-wrap">
            <CardItemBreadcrumbs
              className="features-list"
              breadcrumbs={features.filter(feature => feature.enabled)}
            />
            <Icon name={'chevron-long-right'} size="lg" />
          </div>
        </Link>
      </RouterLink>
    </ProductRecommendationWrapper>
  );
}
