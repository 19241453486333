import React from 'react';
import styled from 'styled-components';
import { FlaglineText, SecondaryHeading } from 'theme/headings';
import { Image, INormalizedImage } from '@hotelplan/components.common.image';
import { Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { ContactTypeUnion } from '@hotelplan/graphql.types';
import { useRequestContext } from '@hotelplan/libs.context.req-ctx';
import {
  getRouterLink,
  getOpenMethod,
} from '@hotelplan/libs.router-link-utils';
import {
  getLinkWithJotform,
  useGclid,
} from 'components/domain/jotform/jotformGclid.utils';
import { useGetAgencyContactQuery } from 'graphql/agency/GetAgencyContact.generated';

interface IContactsSectionProps {
  title: string;
  flagline: string;
  contactTypes: ContactTypeUnion[];
}

const ContactsBlock = styled.div(
  ({ theme: { media, space, colors, fontSizes } }) => ({
    border: '16px solid',
    borderColor: colors.primary,
    backgroundColor: colors.roseFine,
    padding: `50px ${space[3]}`,
    [media.tablet]: {
      border: '20px solid',
      borderColor: colors.primary,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '.section-title': {
      fontSize: '15px',
      lineHeight: '20px',
      marginBottom: space[4],
      textAlign: 'center',
      padding: '0 60px',
    },
    '.section-heading': {
      fontSize: fontSizes[7],
      lineHeight: '42px',
      textAlign: 'center',
      marginBottom: space[6],
      marginLeft: space[2],
      marginRight: space[2],
      [media.tablet]: {
        fontSize: '46px',
        lineHeight: '60px',
        marginBottom: space[6],
      },
    },
  })
);

const ContactsItems = styled.div<{ itemsNumber: number }>(
  ({ theme: { media, text, space }, itemsNumber }) => ({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'baseline',
    gap: `${space[6]} 0`,
    '@media screen and (max-width: 767px) and (orientation: portrait)': {
      'a:first-child': {
        flexBasis: itemsNumber === 3 ? '100%' : '50%',
      },
    },
    [media.tablet]: {
      maxWidth: '1170px',
      gap: '55px',
      flex: '1 0 auto',
    },
    a: {
      textAlign: 'center',
      flexBasis: '50%',
      '@media screen and (min-width: 767px)': {
        flex: '0 0 auto',
      },
      [media.tablet]: {
        flex: '0 0 auto',
      },
    },
    'a:hover': {
      color: '#000000',
    },
    '.phone-text': {
      ...text.groupHeading,
      letterSpacing: '0.1px',
    },
  })
);

const ContactItem = styled.div(({ theme: { media, space } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: space[6],
  minWidth: '160px',
  '.text-block-text': {
    fontSize: '15px',
    lineHeight: '20px',
    textAlign: 'center',
  },
  img: {
    width: '48px',
  },
  [media.mobile]: {
    gap: space[4],
  },
}));

const ContactsSection: React.FC<IContactsSectionProps> = ({
  title,
  flagline,
  contactTypes,
}) => {
  const gclid = useGclid();

  return (
    <ContactsBlock>
      <FlaglineText
        className="section-title"
        dangerouslySetInnerHTML={{ __html: flagline }}
      />
      <SecondaryHeading className="section-heading">{title}</SecondaryHeading>
      <ContactsItems itemsNumber={contactTypes.length}>
        {contactTypes.map((contactItem, index) => {
          switch (contactItem.__typename) {
            case 'ContactType': {
              const { mainTitle, link, icon } = contactItem;

              const linkWithJotform = getLinkWithJotform(link, gclid);
              return (
                <RouterLink
                  {...(linkWithJotform
                    ? getRouterLink(linkWithJotform)
                    : undefined)}
                  passHref
                  key={`contact-item-${index}`}
                >
                  <Link
                    target={
                      linkWithJotform
                        ? getOpenMethod(linkWithJotform)
                        : undefined
                    }
                    testId="contact-item"
                  >
                    <BaseContact icon={icon} title={mainTitle} />
                  </Link>
                </RouterLink>
              );
            }
            case 'ContactTypePhone': {
              const { mainTitle, icon, phone } = contactItem;
              return (
                <ContactPhoneBox key={`contact-item-${index}`} phone={phone}>
                  <BaseContact icon={icon} title={mainTitle} />
                </ContactPhoneBox>
              );
            }
            default:
              return null;
          }
        })}
      </ContactsItems>
    </ContactsBlock>
  );
};

interface IBaseContactProps {
  icon: INormalizedImage;
  title: string;
}

const BaseContact: React.FC<IBaseContactProps> = ({ icon, title }) => {
  return (
    <ContactItem>
      <Image {...icon} />
      <FlaglineText
        className="text-block-text"
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </ContactItem>
  );
};

interface IContactPhoneBoxProps {
  phone?: string;
}

const ContactPhoneBox: React.FC<IContactPhoneBoxProps> = ({
  children,
  phone,
}) => {
  const { agency } = useRequestContext();
  const { data: agencyContactData } = useGetAgencyContactQuery({
    variables: { id: agency || '' },
    skip: Boolean(phone),
  });

  const phoneNumber =
    phone || agencyContactData?.agencyContact.phone.link.caption;

  return (
    <Link href={`tel:${phoneNumber}`} testId="contact-phone-item">
      {children}
      <span className="phone-text">{phoneNumber}</span>
    </Link>
  );
};

export default ContactsSection;
