import { useTranslation } from 'next-i18next';
import React from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  HeroTextBlockGeo,
  SearchInput,
} from 'components/domain/dynamic-components/HeroMediaGalleryWrapper.styles';
import { openSearchOverlay } from 'components/domain/search';
import { THeroMediaGalleryProps } from './HeroMediaGallery.types';
import HeroShareTools from './HeroShareTools';

const GeoHeroMediaGallery: React.FC<THeroMediaGalleryProps> = ({
  component,
  bookmarked,
}) => {
  const { mobile } = useDeviceType();
  const [t] = useTranslation('search');

  return (
    <>
      <HeroShareTools component={component} bookmarked={bookmarked} />
      <HeroTextBlockGeo
        title={component.mainTitle}
        text=""
        color={component.fontColor}
      />
      <SearchInput
        hideLabel
        placeholder={t('search:searchInput.placeholder')}
        icon={{
          name: `search`,
          size: mobile ? 'sm' : 'md',
        }}
        onClick={openSearchOverlay}
      />
    </>
  );
};

export default GeoHeroMediaGallery;
