import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { ThirdHeading } from 'theme/headings';
import { Icon } from '@hotelplan/components.common.icon';
import { Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { OpeningStatus } from 'components/domain/search/Search.common';
import {
  TeaserImageCard,
  TeaserImagePlaceholder,
} from 'components/domain/teaser-image-card';
import { BaseAgencyFragment } from 'graphql/agency/BaseAgency.generated';
import { AgencyRecommendationItemFragment } from 'graphql/recommendations/AgencyRecommendation.generated';
import {
  RecommendationListContainer,
  RecommendationSliderSection,
} from './RecommendationSliderSection';

const AgencyCardWrapper = styled.div(({ theme: { colors, space, text } }) => ({
  '.heading-title': {
    display: 'inline',
  },
  '.image-card': {
    marginBottom: space[3],
  },
  '.heading-wrap': {
    marginBottom: '12px',
    '.icon': {
      color: colors.primary,
      marginLeft: space[2],
      width: '23px',
      verticalAlign: 'text-bottom',
    },
  },
  '.agency-city': {
    ...text.groupHeading,
    marginBottom: '12px',
  },
  'a:hover': {
    color: 'inherit',
  },
}));

interface IAgencyRecommendationProps {
  count: number;
  fontColor?: string;
  backgroundColor?: string;
  title?: string;
  agencyMapSearch?: React.ReactNode;
  testId?: string;
}

export function AgencyRecommendationSection({
  count,
  title,
  fontColor,
  backgroundColor,
  children,
  agencyMapSearch,
  testId,
}: PropsWithChildren<IAgencyRecommendationProps>): ReactElement {
  const [t] = useTranslation('agency');

  // if (!count) return null;

  return (
    <div style={{ color: fontColor, backgroundColor }} data-id={testId}>
      <RecommendationListContainer>
        <RecommendationSliderSection
          title={title || t('agency:agencies.title')}
          count={count}
          titleColor={fontColor}
          customContent={agencyMapSearch}
        >
          {children}
        </RecommendationSliderSection>
      </RecommendationListContainer>
    </div>
  );
}

export function AgencyOverviewRecommendationItem(
  props: BaseAgencyFragment
): ReactElement {
  const { url, image, title, addressDetails, opened } = props;
  const { street, zip, city } = addressDetails || {};

  return (
    <AgencyCardWrapper>
      <TeaserImageCard
        link={url}
        image={image}
        className="image-card"
        title={title}
      />
      {addressDetails ? (
        <RouterLink {...getTargetPageData(url)} passHref>
          <Link>
            <span>{street}</span>
            <br />
            <span>{`${zip} ${city}`}</span>
            {opened !== null ? <OpeningStatus opened={opened} /> : null}
          </Link>
        </RouterLink>
      ) : null}
    </AgencyCardWrapper>
  );
}

export function AgencyOverviewRecommendationItemPlaceholder(): ReactElement {
  return (
    <AgencyCardWrapper>
      <TeaserImagePlaceholder />
      <span>&nbsp;</span>
      <br />
      <span>&nbsp;</span>
      <OpeningStatus opened={true} />
    </AgencyCardWrapper>
  );
}

export function AgencyFlexibleRecommendationItem({
  link,
  name,
  city,
  image,
}: AgencyRecommendationItemFragment): ReactElement {
  return (
    <AgencyCardWrapper>
      <TeaserImageCard link={link} image={image} className="image-card" />
      <RouterLink {...getTargetPageData(link)} passHref>
        <Link>
          <span className="agency-city">{city}</span>
          <div className="heading-wrap">
            <ThirdHeading className="heading-title">{name}</ThirdHeading>
            <Icon name={'chevron-long-right'} />
          </div>
        </Link>
      </RouterLink>
    </AgencyCardWrapper>
  );
}
