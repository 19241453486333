import { useTranslation } from 'next-i18next';
import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { FlaglineText, SecondaryHeading } from 'theme/headings';
import { Icon } from '@hotelplan/components.common.icon';
import { INormalizedImage } from '@hotelplan/components.common.image';
import { Link } from '@hotelplan/components.common.link';
import {
  AddressDetails,
  ExternalLink,
  GeoCoordinates,
  OpeningHours,
  SpecialOpeningHours,
} from '@hotelplan/graphql.types';
import {
  getOpenMethod,
  getRouterLink,
} from '@hotelplan/libs.router-link-utils';
import { formatDate, parseDateStr } from '@hotelplan/libs.utils';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Container } from 'components/domain/container';
import Schedule from 'components/domain/helpOverlay/Schedule';
import {
  useGetLinkUrlWithJotform,
  useGetLinkWithJotform,
} from 'components/domain/jotform/jotformGclid.utils';
import { ScalableImage } from 'components/domain/scalable-image';
import { SpecialOpeningHoursFragment } from 'graphql/fragments/SpecialOpeningHours.generated';
import { RequestButtonFragment } from 'graphql/requestButton/RequestButton.generated';
import { getLocationLink } from './Agencies.utils';
import { mapOpeningHoursToSchedules } from './mapOpeningHoursToSchedules';

interface IAgencyContactModuleProps {
  image?: INormalizedImage;
  name: string;
  flagline: string;
  phone: ExternalLink;
  appointmentLink?: ExternalLink;
  address: AddressDetails;
  coordinates?: GeoCoordinates;
  openingHours: OpeningHours[];
  specialOpeningHours?: SpecialOpeningHours[];
  timezone: string;
  requestButton?: RequestButtonFragment;
}

const AgencyContactModuleWrapper = styled.div(
  sx2CssThemeFn({
    border: ['16px solid', '20px solid'],
    borderColor: ['redWeb', 'redWeb'],
    backgroundColor: 'roseFine',
    padding: '50px 0',
  })
);

const AgencyContactHeadings = styled.div(
  sx2CssThemeFn({
    maxWidth: '1218px',
    margin: ['inherit', '0 auto'],
    '.contacts-flagline': {
      fontSize: '15px',
      lineHeight: '20px',
      mb: 4,
    },
    '.contacts-name': {
      fontSize: [7, '46px'],
      lineHeight: ['42px', '60px'],
      mb: 6,
      fontWeight: 800,
    },
  })
);

const AgencyContactContentBlock = styled.div<{ withImage: boolean }>(
  ({ withImage }) =>
    sx2CssThemeFn({
      display: 'flex',
      justifyContent: withImage ? 'flex-start' : '',
      alignItems: 'flex-start',
      flexDirection: 'row',
      flexWrap: 'wrap',
      maxWidth: '1218px',
      margin: ['inherit', '0 auto'],
      gap: withImage ? [6, 7] : ['30px', '110px'],
    })
);

const AgencyImage = styled.div(
  sx2CssThemeFn({
    maxWidth: '375px',
    width: '100%',
    height: '100%',
    '.agency-image': {
      borderRadius: 'unset',
    },
  })
);

const AgencyIconsBlock = styled.div<{ withImage: boolean }>(({ withImage }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: [6, '40px'],
    maxWidth: ['500px', `${withImage ? '325px' : '650px'}`],
    '.agency-icon-row': {
      display: 'flex',
      alignItems: 'center',
      '.agency-icon-text': {
        minWidth: ['170px', '200px'],
        paddingLeft: 3,
        fontSize: [1, 3],
        lineHeight: 1.2,
      },
    },
    'a:hover': {
      color: 'inherit',
    },
  })
);

const AgencyContactIcon = styled(Icon)(
  sx2CssThemeFn({
    width: ['32px', '48px'],
    height: ['32px', '48px'],
    color: 'redWeb',
    fill: 'transparent',
    '.icon': {
      display: 'block',
      width: '100%',
      height: '100%',
    },
  })
);

const ScheduleWrapper = styled.div(({ theme: { media } }) =>
  sx2CssThemeFn({
    fontSize: [1, 3],
    [media.tablet]: {
      paddingLeft: 4,
    },
    '.agency-schedule': {
      m: 0,
      mb: 1,
    },
    pl: [4, 0],
  })
);

const WEEKDAYS_NAMES = [
  'common:week.shorten.monday',
  'common:week.shorten.tuesday',
  'common:week.shorten.wednesday',
  'common:week.shorten.thursday',
  'common:week.shorten.friday',
  'common:week.shorten.saturday',
  'common:week.shorten.sunday',
];

function formatLongTime(longTime: string): string {
  const [hours, minutes] = longTime.split(':');
  return `${hours}:${minutes}`;
}

interface IScheduleBlock {
  openingHours: SpecialOpeningHoursFragment[];
}

const ScheduleBlock: React.FC<IScheduleBlock> = ({ openingHours }) => {
  const [t, { language }] = useTranslation('agency');

  return (
    <>
      <div>{t('agency:specialOpeningHours.title')}</div>
      {openingHours.map((item, index) => {
        if (!item) return null;

        return (
          <div key={index}>
            {item.date ? (
              <div className="schedule-name">
                {`${formatDate(parseDateStr(item.date), 'EE', language).slice(
                  0,
                  -1
                )}, ${formatDate(parseDateStr(item.date), 'dd.MM.yyyy')}:
              `}
                {!item.closed
                  ? item.shifts
                      ?.map(({ from, to }) =>
                        to
                          ? t('agency:openingHours.shift', {
                              from: formatLongTime(from),
                              to: formatLongTime(to),
                            })
                          : t('agency:openingHours.from', {
                              from: formatLongTime(from),
                            })
                      )
                      .join(' / ')
                  : t('agency:openingHours.closed')}
              </div>
            ) : (
              t(WEEKDAYS_NAMES[index])
            )}
          </div>
        );
      })}
    </>
  );
};

export default function AgencyContactModule({
  name,
  flagline,
  image,
  phone,
  address,
  coordinates,
  openingHours,
  specialOpeningHours,
  timezone,
  requestButton,
  appointmentLink,
}: IAgencyContactModuleProps): ReactElement {
  const [t] = useTranslation('agency');

  const requestButtonLinkWithJotform = useGetLinkWithJotform(
    requestButton.link
  );
  const appointmentLinkWithJotform = useGetLinkUrlWithJotform(
    appointmentLink.url
  );

  const { street, city, zip } = address;

  const locationLink = coordinates ? getLocationLink(coordinates) : undefined;

  const withImage = Boolean(image);

  const showSchedule = openingHours.find(item => item.open);

  return (
    <AgencyContactModuleWrapper>
      <Container>
        <AgencyContactHeadings>
          <FlaglineText
            className="contacts-flagline"
            dangerouslySetInnerHTML={{ __html: flagline }}
          />
          <SecondaryHeading className="contacts-name">{name}</SecondaryHeading>
        </AgencyContactHeadings>
        <AgencyContactContentBlock withImage={withImage}>
          {image ? (
            <AgencyImage>
              <ScalableImage image={image} className="agency-image" />
            </AgencyImage>
          ) : null}
          <AgencyIconsBlock withImage={withImage}>
            <AgencyContactBase
              href={locationLink}
              text={
                <>
                  <p>{street}</p>
                  <span>{`${zip} ${city}`}</span>
                </>
              }
              iconName={'contact-place'}
              target={'_blank'}
            />
            <AgencyContactBase
              href={phone.url}
              text={phone.caption}
              iconName={'contact-phone'}
            />
            <AgencyContactBase
              href={getRouterLink(requestButtonLinkWithJotform).href as string}
              target={getOpenMethod(requestButton.link)}
              text={t('agency:contacts.request')}
              iconName={'contact-email'}
            />
            {appointmentLink ? (
              <AgencyContactBase
                href={appointmentLinkWithJotform}
                target={getOpenMethod(appointmentLink)}
                text={t('agency:contacts.appointmentText')}
                iconName={'contact-calendar'}
              />
            ) : null}
          </AgencyIconsBlock>
          {showSchedule ? (
            <ScheduleWrapper>
              <Schedule
                openingHours={openingHours}
                timezone={timezone}
                showStatusIcon={true}
                className="agency-schedule"
                mapCustomOpeningHoursToSchedules={mapOpeningHoursToSchedules}
              />
              {specialOpeningHours?.length ? (
                <ScheduleBlock openingHours={specialOpeningHours} />
              ) : null}
            </ScheduleWrapper>
          ) : null}
        </AgencyContactContentBlock>
      </Container>
    </AgencyContactModuleWrapper>
  );
}

function AgencyContactBase({
  href,
  text,
  target,
  iconName,
}: {
  href: string;
  text: ReactNode | string;
  iconName: string;
  target?: string;
}): ReactElement {
  return (
    <Link href={href} target={target || '_self'}>
      <div className="agency-icon-row">
        <AgencyContactIcon name={iconName} />
        <span className="agency-icon-text">{text}</span>
      </div>
    </Link>
  );
}
