import { ProductPinType } from 'components/domain/adventure-routing/Routes.types';
import {
  MapClickEventTargetType,
  trackMapClick,
} from 'components/domain/tracking/pdpTracking';
import { ClickEventActionType } from 'components/domain/tracking/tracking.types';
import photoSwitchMap from 'public/images/svg/photo-switch-map.svg';
import routeSwitchMap from 'public/images/svg/route-switch-map.svg';

export enum MapPinControlType {
  pins = 'PINS',
  routePoints = 'ROUTE_POINTS',
}

const mapPinControlTypeToIcon = new Map<MapPinControlType, string>([
  [MapPinControlType.pins, photoSwitchMap],
  [MapPinControlType.routePoints, routeSwitchMap],
]);

const productPinTypeToMapPinControlType = new Map<
  ProductPinType,
  MapPinControlType
>([
  [ProductPinType.PinIcon, MapPinControlType.pins],
  [ProductPinType.RouteIcon, MapPinControlType.routePoints],
]);

function getControlType(pinType: ProductPinType): MapPinControlType {
  return productPinTypeToMapPinControlType.get(pinType);
}

function getControlIcon(controlType: MapPinControlType): string {
  return mapPinControlTypeToIcon.get(controlType);
}

const mapPinControlList = [ProductPinType.PinIcon, ProductPinType.RouteIcon];

function getNextPinType(currentPinType: ProductPinType): ProductPinType {
  const currentIndex = mapPinControlList.indexOf(currentPinType);
  let nextIndex = 0;

  if (currentIndex + 1 < mapPinControlList.length) {
    nextIndex = currentIndex + 1;
  }

  return mapPinControlList[nextIndex];
}

interface IMapPinControlOptions {
  pinType: ProductPinType;
  onChange?(type: ProductPinType): void;
  captionGallery: string;
  captionRouting: string;
  isPdp: boolean;
}

export function createDefaultMapPinControl({
  pinType,
  onChange,
  captionGallery,
  captionRouting,
  isPdp,
}: IMapPinControlOptions): HTMLDivElement | undefined {
  if (process.browser) {
    const mapPinControl = document.createElement('div');
    const controlUI = document.createElement('div');
    const controlIcon = document.createElement('img');
    const controlIconCaption = document.createElement('div');
    const iconCaption = document.createElement('p');

    let currentPinType = pinType;

    const nextPinType = getNextPinType(currentPinType);

    controlUI.classList.add(`map-control-button`);
    controlIconCaption.classList.add(`map-control-caption`);

    controlIcon.src = getControlIcon(getControlType(nextPinType));

    iconCaption.textContent =
      pinType === ProductPinType.PinIcon ? captionRouting : captionGallery;

    controlUI.addEventListener('click', () => {
      const newPinType = getNextPinType(currentPinType);
      const newNextPinType = getNextPinType(newPinType);

      controlIcon.src = getControlIcon(getControlType(newNextPinType));

      currentPinType = newPinType;
      onChange(newPinType);

      const isPins = newPinType === ProductPinType.PinIcon;

      if (isPdp) {
        trackMapClick(
          ClickEventActionType.SWITCH,
          isPins
            ? MapClickEventTargetType.MAP_PDP_IMAGE_VIEW
            : MapClickEventTargetType.MAP_PDP_ROUTING_VIEW
        );
      }

      iconCaption.textContent = isPins ? captionRouting : captionGallery;
    });

    controlIconCaption.appendChild(iconCaption);
    controlUI.appendChild(controlIcon);
    controlUI.appendChild(controlIconCaption);
    mapPinControl.appendChild(controlUI);

    return mapPinControl;
  }
}
