import React, { useState } from 'react';
import styled from 'styled-components';
import { GeoProduct } from '@hotelplan/platform-graphql-api';
import { SlidersNavigationContainer } from 'components/domain/container';
import usePaginationVariant from 'components/domain/customizable-slider/usePaginationVariant';
import { PaginationArrowClickSource } from 'components/domain/tracking/paginationTracking';
import { GeoModulesFragment } from 'graphql/geo/GetGeoModules.generated';
import { StaticProductRecommendationFragment } from 'graphql/recommendations/StaticProductRecommendation.generated';
import {
  RecommendationSliderSection,
  RecommendationListContainer,
} from './RecommendationSliderSection';

const DEFAULT_IMAGE_HEIGHT = 387;

export interface IComponentData
  extends Pick<
    StaticProductRecommendationFragment,
    'backgroundColor' | 'fontColor' | 'name'
  > {
  items: GeoProduct[] | GeoModulesFragment['products'];
}

interface ISectionProps {
  testId?: string;
  component: StaticProductRecommendationFragment | IComponentData;
  id?: string;
  customContent?: React.ReactNode;
  sectionHeaderTitle?: string;
}

const RecommendationListWithSlidesNavigationContainer = styled(
  SlidersNavigationContainer
)({
  paddingTop: '80px',
  paddingBottom: '80px',
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '20px',
  scrollMarginTop: '100px',
  '.count': {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '20px',
  },
});

const CommonRecommendationSection: React.FC<
  React.PropsWithChildren<ISectionProps>
> = ({
  component,
  testId,
  children,
  id,
  sectionHeaderTitle,
  customContent,
}) => {
  const count = component?.items.length;
  const [imageHeight, setImageHeight] = useState(DEFAULT_IMAGE_HEIGHT);
  const isSliderPagination = usePaginationVariant();

  if (!count) return null;
  const { backgroundColor, fontColor, name } = component;

  const RecommendationListContainerVariant = isSliderPagination
    ? RecommendationListWithSlidesNavigationContainer
    : RecommendationListContainer;

  return (
    <div style={{ color: fontColor, backgroundColor }} data-id={testId} id={id}>
      <RecommendationListContainerVariant>
        <RecommendationSliderSection
          title={name}
          count={count}
          titleColor={fontColor}
          customContent={customContent}
          imageHeight={imageHeight}
          isSliderPagination={isSliderPagination}
          trackingSource={PaginationArrowClickSource.PRODUCTS}
          sectionHeaderTitle={sectionHeaderTitle}
        >
          {typeof children === 'function' ? children(setImageHeight) : children}
        </RecommendationSliderSection>
      </RecommendationListContainerVariant>
    </div>
  );
};

export default CommonRecommendationSection;
