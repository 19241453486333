import { useTranslation } from 'next-i18next';
import React from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  HeroTextBlockHome,
  SearchInput,
} from 'components/domain/dynamic-components/HeroMediaGalleryWrapper.styles';
import { openSearchOverlay } from 'components/domain/search';
import { DynamicComponentHeroMediaGalleryFragment } from 'graphql/components/GetComponents.generated';

type TProps = {
  component: DynamicComponentHeroMediaGalleryFragment;
};

const HomeHeroMediaGallery: React.FC<TProps> = ({ component }: TProps) => {
  const { mobile } = useDeviceType();
  const [t] = useTranslation('search');

  return (
    <>
      <HeroTextBlockHome
        title={component.mainTitle}
        text=""
        color={component.fontColor}
      />
      <SearchInput
        hideLabel
        placeholder={t('search:searchInput.placeholder')}
        icon={{
          name: `search`,
          size: mobile ? 'sm' : 'md',
        }}
        onClick={openSearchOverlay}
      />
    </>
  );
};

export default HomeHeroMediaGallery;
