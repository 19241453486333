import isEmpty from 'lodash/fp/isEmpty';
import omit from 'lodash/fp/omit';
import pipe from 'lodash/fp/pipe';
import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { FaqSection } from '@hotelplan/components.common.faq-section';
import { RawTextBlock } from '@hotelplan/components.common.raw-text-block';
import { TextBlock } from '@hotelplan/components.common.text-block';
import {
  OverlaySlideInfo,
  SlideInfo,
  BsRsvGallery,
} from '@hotelplan/core.serving.bs-rsv-gallery';
import {
  AgencyRecommendation,
  StaticGeoRecommendation,
  StaticGeoRecommendationItem,
} from '@hotelplan/graphql.types';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import AgencyContactModule from 'components/domain/agency/AgencyContactModule';
import BenefitsSection from 'components/domain/benefits/BenefitsSection';
import ContactsSection from 'components/domain/contacts-section/ContactsSection';
import { Container } from 'components/domain/container';
import { renderMedia } from 'components/domain/criterias';
import HeroMediaGalleryWrapper from 'components/domain/dynamic-components/HeroMediaGalleryWrapper';
import GeoRecommendationItem from 'components/domain/geo/GeoRecommendationItem';
import ProductGalleryWithMap from 'components/domain/product-grallery-with-map/ProductGalleryWithMap';
import {
  AgencyFlexibleRecommendationItem,
  AgencyRecommendationSection,
} from 'components/domain/recommendations/AgencyRecommendationSection';
import BlogArticleRecommendationsSection from 'components/domain/recommendations/BlogArticleRecommendationsSection';
import CommonGridRecommendationSection from 'components/domain/recommendations/CommonGridRecommendationSection';
import { MarketingRecommendationsSection } from 'components/domain/recommendations/MarketingRecommendationsSection';
import { ProductRecommendationsSection } from 'components/domain/recommendations/ProductRecommendationsSection';
import { ThemeRecommendationsSection } from 'components/domain/recommendations/ThemeRecommendationsSection';
import StaticRequestButton from 'components/domain/static-request-button/StaticRequestButton';
import { TextBlockContainer } from 'components/domain/text-block/TextBlockContainer';
import UspSection from 'components/domain/usp-section/UspSection';
import type { DynamicComponentFragment } from 'graphql/components/GetComponents.generated';

interface IDynamicComponentsProps {
  components?: DynamicComponentFragment[];
  bookmarked?: boolean;
}

const isObjectEmpty = pipe(omit(`__typename`), isEmpty);
const strictFields = ['strictFontColor', 'strictBackgroundColor', 'strictName'];

function mapComponentFieldsFromStrictToCommon(component) {
  return Object.assign(omit(strictFields)(component), {
    fontColor: component.strictFontColor,
    backgroundColor: component.strictBackgroundColor,
    [component.name ? 'name' : 'title']:
      component.strictName || component.name || component.title,
  });
}

const TextBlockWrapper = styled(TextBlockContainer)(
  sx2CssThemeFn({
    paddingBottom: ['50px', 9],
    paddingTop: ['50px', 9],
    overflowX: 'unset',
    '&.small-padding-bottom': {
      paddingBottom: [4, '40px'],
    },
    '&.small-padding-top': {
      paddingTop: [4, '40px'],
    },
    '&.none-padding-bottom': {
      paddingBottom: [0, 0],
    },
    '&.none-padding-top': {
      paddingTop: [0, 0],
    },
    '.large-text-width': {
      width: '100%',
      maxWidth: '100%',
      '.text-block-text': {
        width: '100%',
      },
    },
    '.text-block-text': {
      fontSize: '15px',
    },
    '&.anchor-section, .anchor-section': {
      scrollMarginTop: ['0', '150px'],
      scrollPaddingTop: ['0', '150px'],
    },
  })
);

const BackgroundWrap = styled.div<{
  backgroundColor?: string;
  backgroundImage?: string;
  fontColor?: string;
}>(({ backgroundColor, backgroundImage, fontColor }) => {
  return {
    backgroundColor: backgroundImage ? null : backgroundColor,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    color: fontColor,
  };
});

const FaqContainer = styled(Container)(({ theme: { media } }) => ({
  maxWidth: '858px',
  '.accordionItem  ': {
    [media.tablet]: {
      marginLeft: '-15px',
      marginRight: '-15px',
    },
  },
}));

const MediaGalleryContainer = styled(Container)(
  sx2CssThemeFn({
    my: ['30px', '70px'],
    px: [3, '20px'],
    '.open-preview': {
      mr: [3, '40px'],
      mb: 3,
      '.icon': {
        width: '32px',
        height: '32px',
      },
    },
    '> div': {
      maxHeight: '640px',
    },
    '.slide-title': {
      maxWidth: '940px',
    },
  })
);

const RequestButtonWrapper = styled.div(
  sx2CssThemeFn({
    '.static-request-button-wrapper': {
      mt: [-6, -8],
      pb: [6, 9],
      maxWidth: '818px',
      pl: [3, 0],
      pr: [3, 0],
    },
    '.large-text-width': {
      width: '100%',
      maxWidth: '1480px',
      pl: [3, '20px'],
      pr: [3, '20px'],
    },
  })
);

const DynamicComponents: React.FC<IDynamicComponentsProps> = ({
  components,
  bookmarked,
}) => {
  const [t] = useTranslation(['common']);
  const textWidthRef = useRef<string>('');

  return (
    <>
      {components
        ?.map((component, index) => {
          if (isObjectEmpty(component)) return null;

          switch (component.__typename) {
            case 'TextComponent': {
              const {
                type,
                title,
                text,
                textWidth,
                bottomPadding,
                topPadding,
                backgroundColor,
                backgroundImage,
                fontColor,
              } = component;

              const textBlockWidth =
                textWidth === 'LARGE' ? 'large-text-width' : '';

              textWidthRef.current = textBlockWidth;

              const textBlockBottomPadding =
                bottomPadding === 'PADDING_NONE'
                  ? 'none-padding-bottom'
                  : bottomPadding === 'PADDING_SMALL'
                  ? 'small-padding-bottom'
                  : '';

              const textBlockTopPadding =
                topPadding === 'PADDING_NONE'
                  ? 'none-padding-top'
                  : topPadding === 'PADDING_SMALL'
                  ? 'small-padding-top'
                  : '';

              const imgUrl = backgroundImage?.resized.filter(
                image => image.id === '1x_desktop'
              )[0]?.url;

              return (
                <BackgroundWrap
                  key={`dc-${index}`}
                  backgroundColor={backgroundColor}
                  backgroundImage={imgUrl}
                  fontColor={fontColor}
                >
                  <TextBlockWrapper
                    key={`text-${index}`}
                    className={`${textBlockBottomPadding} ${textBlockTopPadding} anchor-section`}
                  >
                    {type === 'raw' ? (
                      <RawTextBlock
                        uid={`raw-${index}`}
                        title={title}
                        text={text}
                        className={`raw-text ${textBlockWidth}`}
                      />
                    ) : (
                      <TextBlock
                        id={`markdown-${index}`}
                        title={title}
                        text={text}
                        className={`markdown-component ${textBlockWidth}`}
                      />
                    )}
                  </TextBlockWrapper>
                </BackgroundWrap>
              );
            }
            case 'MarketingRecommendation': {
              return (
                <MarketingRecommendationsSection
                  key={`recommendation-group-${index}`}
                  testId="marketing-recommendations-section"
                  {...component}
                />
              );
            }
            case 'StaticProductRecommendation': {
              return (
                <ProductRecommendationsSection
                  key={`static-product-rec-group-${index}`}
                  testId="product-recommendations-section"
                  component={component}
                />
              );
            }
            case 'StaticThemeRecommendation': {
              return (
                <ThemeRecommendationsSection
                  key={`static-theme-rec-group-${index}`}
                  testId="theme-recommendations-section"
                  {...component}
                />
              );
            }
            case 'StaticGeoRecommendation': {
              const geoComponent = mapComponentFieldsFromStrictToCommon(
                component
              ) as StaticGeoRecommendation;

              if (!geoComponent.items.length) return null;

              return (
                <CommonGridRecommendationSection<StaticGeoRecommendationItem>
                  key={`geo-rec-section-${index}`}
                  testId="geo-recommendations-section"
                  items={geoComponent.items as StaticGeoRecommendationItem[]}
                  mainTitle={geoComponent.name}
                  fontColor={geoComponent.fontColor}
                  bgColor={geoComponent.backgroundColor}
                  perPage={geoComponent.displayNumber}
                  recommendationItem={GeoRecommendationItem}
                />
              );
            }
            case 'AgencyRecommendation': {
              const agencyComponent = mapComponentFieldsFromStrictToCommon(
                component
              ) as AgencyRecommendation;

              const {
                items,
                fontColor,
                backgroundColor,
                title,
              } = agencyComponent;

              return (
                <AgencyRecommendationSection
                  key={`agency-recommendation-section-${index}`}
                  testId="agency-recommendations-section"
                  count={items.length}
                  fontColor={fontColor}
                  backgroundColor={backgroundColor}
                  title={title}
                >
                  {items.map((agency, i) => {
                    return (
                      <AgencyFlexibleRecommendationItem
                        key={`agency-recommendation-item-${i}`}
                        {...agency}
                      />
                    );
                  })}
                </AgencyRecommendationSection>
              );
            }
            case 'StaticBlogRecommendation': {
              const { title, articles } = component;
              return (
                <BlogArticleRecommendationsSection
                  key={`blog-article-section-${index}`}
                  items={articles}
                  title={title}
                />
              );
            }
            case 'HeroMediaGallery': {
              return (
                <HeroMediaGalleryWrapper
                  component={component}
                  bookmarked={bookmarked}
                  key={`heroes-${index}`}
                />
              );
            }
            case 'FaqComponent': {
              return (
                <FaqContainer key={`faq-section-${index}`}>
                  <FaqSection items={component.items} />
                </FaqContainer>
              );
            }
            case 'UspBoxesComponent': {
              return (
                <UspSection
                  key={`usp-section-${index}`}
                  fontColor={component.fontColor}
                  bgColor={component.backgroundColor}
                  boxes={component.boxes}
                  title={component.uspTitle}
                />
              );
            }
            case 'MediaGallery': {
              return (
                <MediaGalleryContainer key={`mg-${index}`}>
                  <BsRsvGallery
                    items={component.mediaItems}
                    renderSlide={renderMedia}
                    overlaySlideInfo={(
                      item,
                      slideIndex,
                      count,
                      isHideNavigation
                    ) => (
                      <OverlaySlideInfo
                        item={item}
                        slide={slideIndex + 1}
                        count={count}
                        copyrightLabel={t('copyright.photo')}
                        isHideNavigation={isHideNavigation}
                      />
                    )}
                    slideInfo={(item, slideIndex, count, isHideNavigation) => (
                      <SlideInfo
                        item={item}
                        slide={slideIndex + 1}
                        count={count}
                        copyrightLabel={t('copyright.photo')}
                        isHideNavigation={isHideNavigation}
                      />
                    )}
                  />
                </MediaGalleryContainer>
              );
            }
            case 'ContactModuleComponent': {
              const { mainTitle, flagline, contactTypeBases } = component;
              return (
                <ContactsSection
                  key={`contacts-section-${index}`}
                  title={mainTitle}
                  flagline={flagline}
                  contactTypes={contactTypeBases}
                />
              );
            }
            case 'ProductGalleryWithMapComponent': {
              const { title, images } = component;
              return (
                <ProductGalleryWithMap
                  key={`product-gallery-with-map-${index}`}
                  currentProduct={{
                    name: title,
                    images: images,
                  }}
                />
              );
            }
            case 'RequestButtonComponent': {
              const { backgroundColor, caption, link } = component;

              if (!link) return null;

              return (
                <RequestButtonWrapper key={`r-b-${index}`}>
                  <StaticRequestButton
                    backgroundColor={backgroundColor}
                    caption={caption}
                    link={link}
                    className={textWidthRef.current}
                  />
                </RequestButtonWrapper>
              );
            }
            case 'AgencyContactComponent': {
              const {
                title,
                openingHours,
                coordinates,
                image,
                agencyName,
                phone,
                address,
                requestButton,
                timezone,
              } = component;

              return (
                <AgencyContactModule
                  key={`acm-${index}`}
                  name={agencyName}
                  flagline={title}
                  openingHours={openingHours}
                  coordinates={coordinates}
                  image={image}
                  phone={phone}
                  address={address}
                  timezone={timezone}
                  requestButton={requestButton}
                />
              );
            }
            case 'BenefitsComponent': {
              const { text, items, buttonLabel, buttonLink } = component;

              return (
                <BenefitsSection
                  key={`benefits-${index}`}
                  text={text}
                  items={items}
                  buttonLabel={buttonLabel}
                  buttonLink={buttonLink}
                />
              );
            }
            default:
              return null;
          }
        })
        .filter(Boolean)}
    </>
  );
};

export default DynamicComponents;
