import React, { FC } from 'react';
import styled from 'styled-components';
import { HeroMedia, ImageMediaItem, PageType } from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { bookmarkablePageTypes } from 'components/domain/bookmark/Bookmark.types';
import BookmarkBlock from 'components/domain/bookmark/BookmarkBlock';
import ShareSiteTool from 'components/domain/sitetools/ShareSiteTool';
import { HeroToolsWrap } from 'components/domain/sitetools/SiteTools.common';
import { useShareUrl } from 'components/domain/sitetools/use-share-url';
import { BookmarkComponentType } from 'components/domain/tracking/bookmarkTracking';
import { THeroMediaGalleryProps } from './HeroMediaGallery.types';

const BookmarkSiteTool = styled.div.attrs({
  className: 'site-tools-button-wrapper-bookmark',
})({});

const HeroShareTools: FC<THeroMediaGalleryProps> = ({
  bookmarked,
  component,
}) => {
  const pageType = usePageType<PageType>();
  const { id } = useObjectId();

  const shareUrl = useShareUrl();

  const anyHeroImage = component.mediaItems?.find(
    (item: HeroMedia): item is ImageMediaItem =>
      item.__typename === 'ImageMediaItem'
  );

  return (
    <HeroToolsWrap>
      <ShareSiteTool
        pageTitle={component.mainTitle}
        image={anyHeroImage?.image}
        url={shareUrl}
      />
      {bookmarkablePageTypes.includes(pageType) && (
        <BookmarkSiteTool>
          <BookmarkBlock
            pageType={pageType}
            objectId={id}
            active={bookmarked}
            trackingComponent={BookmarkComponentType.CONTENT}
          />
        </BookmarkSiteTool>
      )}
    </HeroToolsWrap>
  );
};

export default HeroShareTools;
