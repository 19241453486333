import { useGoogleMap } from '@react-google-maps/api';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import {
  mapCoordinatesToLatLng,
  MapPin,
  MapPinView,
} from '@hotelplan/components.common.map-pin';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { RouteContext } from 'components/domain/adventure-routing/Routes.context';
import { ProductPinType } from 'components/domain/adventure-routing/Routes.types';
import { pinContent } from 'components/domain/map/Map.styles';
import { ProductImageItemFragment } from 'graphql/fragments/ProductGalleryWithMap.generated';
import { IProductPinItem } from './Pins.types';

interface IProductPinProps {
  selected: boolean;
  visible: boolean;
  item: IProductPinItem;
  showDetails?: boolean;
  onClick(group: ProductImageItemFragment): void;
  index: number;
}

const ProductPinRouteIcon = styled.div<{ visible: boolean }>(({ visible }) =>
  sx2CssThemeFn({
    display: visible ? 'block' : 'none',
    width: '19px',
    height: '19px',
    position: 'relative',
    textAlign: 'center',
    borderRadius: '50%',
    fontSize: '12px',
    fontWeight: '900',
    background: '#E4251B',
    color: 'white',
  })
);

const ProductPinRouteInfoWindowContainer = styled.div(
  sx2CssThemeFn({
    position: 'relative',
  })
);

const ProductPinRouteInfoWindow = styled.div(
  sx2CssThemeFn({
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0 12px',
    border: '1px solid #707070',
    marginTop: '-29px',
    borderRadius: '3px',
    background: '#fff',
    color: '#000',
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    fontSize: '10px',
  })
);

const MapPinWrapper = styled(MapPin)<{
  productPinType?: ProductPinType;
  index: number;
}>(({ productPinType, index }) => {
  switch (productPinType) {
    default:
    case ProductPinType.PinIcon:
      return sx2CssThemeFn(pinContent);

    case ProductPinType.RouteIcon:
      return sx2CssThemeFn(pinContent, {
        zIndex: index === 0 ? 1 : 'auto',
        '.map-pin-wrapper:after': {
          display: 'none',
        },
      });
  }
});

const LOWERCASE_ALPHABET = 'abcdefghijklmnopqrstuvwxyz'.split('');

const indexToChar = _.memoize(index => {
  return LOWERCASE_ALPHABET[index % LOWERCASE_ALPHABET.length];
});

const ProductPin: React.FC<IProductPinProps> = ({
  selected,
  item,
  onClick,
  index,
  showDetails,
  visible,
}) => {
  const ref = useRef<MapPinView>(null);
  const { pinType } = useContext(RouteContext);
  const map = useGoogleMap();
  const [showDetailedInfo, setShowDetailedInfo] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    google.maps.event.trigger(ref.current, selected ? 'select' : 'deselect');
  }, [selected]);

  useEffect(() => {
    if (!map || !selected) return;

    requestAnimationFrame(() => {
      map.panTo(mapCoordinatesToLatLng(item.coordinates));
    });
  }, [map, selected]);

  return (
    <MapPinWrapper
      viewRef={ref}
      coordinates={item.coordinates}
      object={item}
      onClick={onClick}
      initialSelected={selected}
      productPinType={pinType}
      index={index}
    >
      {pinType === ProductPinType.PinIcon && (
        <Icon
          name={selected ? 'map-pin-active' : 'map-pin-inactive'}
          size="xlg"
        />
      )}
      {pinType === ProductPinType.RouteIcon && (
        <>
          <ProductPinRouteIcon
            visible={visible}
            onMouseEnter={() => {
              setShowDetailedInfo(true);
            }}
            onMouseLeave={() => {
              setShowDetailedInfo(false);
            }}
          >
            {indexToChar(index)}
          </ProductPinRouteIcon>
          <ProductPinRouteInfoWindowContainer>
            {(showDetails || showDetailedInfo) && (
              <ProductPinRouteInfoWindow>{item.name}</ProductPinRouteInfoWindow>
            )}
          </ProductPinRouteInfoWindowContainer>
        </>
      )}
    </MapPinWrapper>
  );
};

export default ProductPin;
