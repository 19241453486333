import React, { useMemo } from 'react';
import { PageType } from '@hotelplan/graphql.types';
import { usePageType } from '@hotelplan/libs.context.page-type';
import AnyHeroMediaGallery from 'components/domain/dynamic-components/AnyHeroMediaGallery';
import { HeroMediaContent } from 'components/domain/dynamic-components/HeroMediaGalleryWrapper.styles';
import HomeHeroMediaGallery from 'components/domain/dynamic-components/HomeHeroMediaGallery';
import { HeroMediaGallery } from 'components/domain/hero-media-gallery';
import HeroOgTags from 'components/domain/layout/HeroOgTags';
import GeoHeroMediaGallery from './GeoHeroMediaGallery';
import { THeroMediaGalleryProps } from './HeroMediaGallery.types';

const switchHeroGallery = (
  pageType: PageType,
  component,
  bookmarked
): React.ReactNode => {
  switch (pageType) {
    case PageType.Home: {
      return <HomeHeroMediaGallery component={component} />;
    }
    case PageType.GeoOverview: {
      return (
        <GeoHeroMediaGallery component={component} bookmarked={bookmarked} />
      );
    }
    default: {
      return (
        <AnyHeroMediaGallery component={component} bookmarked={bookmarked} />
      );
    }
  }
};

const HeroMediaGalleryWrapper: React.FC<THeroMediaGalleryProps> = ({
  component,
  bookmarked,
}) => {
  const pageType = usePageType() as PageType;

  const firstItem = component.mediaItems[0];
  const type = firstItem.__typename;
  const image = type === 'VideoMediaItem' ? firstItem.preview : firstItem.image;

  const renderHeroMediaGallery = useMemo(
    () => switchHeroGallery(pageType, component, bookmarked),
    [pageType, component, bookmarked]
  );

  return (
    <HeroMediaGallery
      items={component.mediaItems || []}
      ratio={0}
      loop
      muted
      fullScreen={pageType === PageType.Home}
      className={pageType === PageType.Home ? 'home-hero-section' : undefined}
    >
      <HeroOgTags image={image} />
      <HeroMediaContent
        className={pageType === PageType.Home ? 'is-home' : 'not-home'}
      >
        {renderHeroMediaGallery}
      </HeroMediaContent>
    </HeroMediaGallery>
  );
};

export default HeroMediaGalleryWrapper;
