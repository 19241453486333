import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ProductFilter } from '@hotelplan/platform-graphql-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import Filters from './Filters';

const ProductRecommendationCustomContentWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    marginBottom: 6,
    paddingRight: 3,
    justifyContent: 'space-between',
    '.filters-wrapper': {
      marginBottom: 0,
    },
  })
);

const ProductRecommendationCustomContent: React.FC<
  PropsWithChildren<{
    productFilters?: ProductFilter[];
  }>
> = ({ productFilters, children }) => {
  if (!productFilters?.length && !React.Children.count(children)) {
    return null;
  }

  return (
    <ProductRecommendationCustomContentWrapper>
      <Filters items={productFilters?.length ? productFilters : []} />
      {children}
    </ProductRecommendationCustomContentWrapper>
  );
};

export default ProductRecommendationCustomContent;
