import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { ProductFilter } from '@hotelplan/platform-graphql-api';
import { useRemoveRouteId } from './useRemoveRouteId';

type TProps = {
  items: ProductFilter[];
};

const FiltersWrapper = styled.div.attrs({
  className: 'filters-wrapper',
})(({ theme: { colors } }) => ({
  display: 'flex',
  marginBottom: '40px',
  columnGap: '20px',
  '.item': {
    borderRadius: '5px',
    border: `1px solid ${colors.primary}`,
    padding: '3px 44px 3px 20px',
    position: 'relative',
    color: colors.primary,
    fontSize: '14px',
    fontWeight: 900,
    height: '40px',
    background: '#fff',
    '.x': {
      background: colors.primary,
      position: 'absolute',
      right: '0px',
      top: '0px',
      bottom: '0px',
      width: '24px',
      textAlign: 'center',
      color: 'white',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      svg: {
        width: '14px',
      },
    },
  },
}));

const Filters: React.FC<TProps> = ({ items }: TProps) => {
  const handleClick = useRemoveRouteId();

  return (
    <FiltersWrapper>
      {items.map(filter => {
        return (
          <button
            key={filter.objectId}
            className="item"
            onClick={handleClick(filter.objectId)}
          >
            {filter.objectName}
            <span className="x">
              <Icon name="close-white" />
            </span>
          </button>
        );
      })}
    </FiltersWrapper>
  );
};

export default Filters;
