import { CSSObject } from 'styled-components';
import { AgencyBrand, GeoCoordinates } from '@hotelplan/graphql.types';
import { isIOS } from '@hotelplan/libs.utils';
import { BaseAgencyFragment } from 'graphql/agency/BaseAgency.generated';

const SELECTED_AGENCY_PIN = `agency-pin-selected`;
const AGENCY_PIN = `agency-pin`;

interface IAgencyIcon {
  agencyIcon: string;
  pinIcon: string;
}

const agencyIconsMap = {
  [AgencyBrand.Hotelplan]: `hotelplanpin`,
  [AgencyBrand.Travelhouse]: `travelhousepin`,
  [AgencyBrand.Sv]: `sv-white`,
  [AgencyBrand.Tpt]: `tpt-white`,
};

export function getAgencyIcons(
  agency: BaseAgencyFragment,
  selected = false
): IAgencyIcon {
  return {
    agencyIcon: agencyIconsMap[agency.brand] || '',
    pinIcon: selected ? SELECTED_AGENCY_PIN : AGENCY_PIN,
  };
}

const mainAgencies = [AgencyBrand.Travelhouse, AgencyBrand.Tpt];

export function agencyZIndex(agency: BaseAgencyFragment): CSSObject {
  return mainAgencies.includes(agency.brand) ? { zIndex: 1 } : {};
}

export function getLocationLink(coord: GeoCoordinates): string {
  const { latitude, longitude } = coord;

  return isIOS
    ? `https://maps.apple.com/?daddr=${latitude},${longitude}`
    : `https://www.google.com/maps/dir/?api=1&destination=${latitude}%2C${longitude}`;
}
