import React from 'react';
import { HeroTextBlock } from 'components/domain/dynamic-components/HeroMediaGalleryWrapper.styles';
import { THeroMediaGalleryProps } from './HeroMediaGallery.types';
import HeroShareTools from './HeroShareTools';

const AnyHeroMediaGallery: React.FC<THeroMediaGalleryProps> = ({
  component,
  bookmarked,
}) => {
  return (
    <>
      <HeroShareTools component={component} bookmarked={bookmarked} />
      <HeroTextBlock
        title={component.mainTitle}
        text={component.flagline}
        color={component.fontColor}
      />
    </>
  );
};

export default AnyHeroMediaGallery;
