import React from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import { Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { getRouterLink } from '@hotelplan/libs.router-link-utils';
import { Image, InternalLink } from '@hotelplan/platform-graphql-api';
import {
  GridRecommendationItem,
  GridRecommendationItemImage,
  GridRecommendationMobileButtonItems,
  GridRecommendationTitle,
} from 'components/domain/recommendations/CommonGridRecommendationSection.styles';

interface IGeoRecommendationItemProps {
  item: {
    id: string;
    title: string;
    image: Image;
    link: InternalLink;
  };
}

const GeoRecommendationItem: React.FC<IGeoRecommendationItemProps> = ({
  item,
}) => {
  const { mobile } = useDeviceType();
  const { title, image, link, id } = item;

  return (
    <RouterLink
      key={`geo-item-${id}`}
      {...(link ? getRouterLink(link) : undefined)}
      passHref
    >
      {!mobile ? (
        <Link className="geo-item-wrapper">
          <GridRecommendationItem className="geo-item">
            <GridRecommendationItemImage {...image} />
          </GridRecommendationItem>
          <GridRecommendationTitle className="geo-title">
            <span>{title}</span>
            <Icon name="chevron-long-right" className="icon" size="lg" />
          </GridRecommendationTitle>
        </Link>
      ) : (
        <Link className="geo-item-wrapper">
          <GridRecommendationMobileButtonItems>
            <GridRecommendationTitle className="geo-title">
              <span>{title}</span>
              <Icon name="chevron-long-right" className="icon" size="lg" />
            </GridRecommendationTitle>
          </GridRecommendationMobileButtonItems>
        </Link>
      )}
    </RouterLink>
  );
};

export default GeoRecommendationItem;
