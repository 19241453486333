import React from 'react';
import styled from 'styled-components';
import { Link } from '@hotelplan/components.common.link';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Container } from 'components/domain/container';
import { NavigationIds } from 'components/domain/geo/navigation/GeoNavigation.types';
import { useGetLinkWithJotform } from 'components/domain/jotform/jotformGclid.utils';
import {
  LinkFragmentExternalLinkFragment,
  LinkFragmentFragment,
  LinkFragmentInternalLinkFragment,
} from 'graphql/link/Link.generated';
import BenefitItems from './BenefitItems';

interface IBenefitsSectionProps {
  text: string;
  items: { text?: string }[];
  buttonLabel: string;
  buttonLink: LinkFragmentFragment;
}

export const BenefitSectionContent = styled.div(
  sx2CssThemeFn({
    backgroundColor: 'paperGrey',
  })
);

export const BenefitContainer = styled(Container)<{ mobile: boolean }>(
  ({ theme: { space }, mobile }) => ({
    padding: mobile ? `50px ${space[3]}` : '80px 20px',
  })
);

const BenefitButtonContainer = styled.div<{ mobile: boolean }>(
  ({ theme }) => theme.requestButton.primary,
  ({ theme: { space }, mobile }) => ({
    marginTop: mobile ? space[6] : '40px',
    textAlign: 'center',
    display: 'block',
  })
);

const BenefitButtonLink = styled(Link)(({ theme }) => theme.buttons.primary);

export const BenefitText = styled.div<{ mobile: boolean }>(
  ({ theme: { space, fontSizes }, mobile }) => ({
    marginBottom: mobile ? space[6] : '40px',
    fontSize: mobile ? fontSizes[2] : fontSizes[3],
    lineHeight: mobile ? 1.375 : 1.6,
    ...(!mobile && {
      width: '800px',
    }),
    h2: {
      fontSize: mobile ? fontSizes[7] : fontSizes[8],
      lineHeight: 1.3,
      marginBottom: space[4],
    },
  })
);

const BenefitsSection: React.FC<IBenefitsSectionProps> = props => {
  const { text, items, buttonLabel, buttonLink } = props;
  const { mobile } = useDeviceType();

  const linkWithJotform = useGetLinkWithJotform(buttonLink);

  const href =
    (linkWithJotform as LinkFragmentInternalLinkFragment).uri ??
    (linkWithJotform as LinkFragmentExternalLinkFragment).url;
  const target =
    (buttonLink as LinkFragmentExternalLinkFragment).openMethod ?? '_self';

  return (
    <BenefitSectionContent id={NavigationIds.BENEFITS}>
      <BenefitContainer mobile={mobile}>
        <BenefitText
          mobile={mobile}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        <BenefitItems items={items} />
        <BenefitButtonContainer mobile={mobile}>
          <BenefitButtonLink
            className={'request-button'}
            target={target}
            href={href}
          >
            {buttonLabel}
          </BenefitButtonLink>
        </BenefitButtonContainer>
      </BenefitContainer>
    </BenefitSectionContent>
  );
};

export default BenefitsSection;
