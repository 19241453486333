import { useRouter } from 'next/router';

const replaceId = (id?: string) => {
  const url = new URL(window?.location.href);

  const ctx = url.searchParams
    .get(`navigationcontext`)
    .split(',')
    .filter(ctxId => ctxId !== id)
    .join(',');

  if (!ctx) {
    url.searchParams.delete(`navigationcontext`);
  } else {
    url.searchParams.set(`navigationcontext`, ctx);
  }

  return url.pathname;
};

type TReturnType = () => (id?: string) => () => void;

export const useRemoveRouteId: TReturnType = () => {
  const router = useRouter();

  return (id?: string) => () => router.push(replaceId(id));
};
