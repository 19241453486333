import React, { ReactElement, useCallback, useState } from 'react';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { getRouterLink } from '@hotelplan/libs.router-link-utils';
import { BlogArticleItemFragment } from 'graphql/blogArticle/BlogArticleItem.generated';
import {
  BlogArticleLink,
  BlogArticleRecommendationSliderSection,
} from './BlogArticle.styles';
import BlogArticleItem from './BlogArticleItem';
import { RecommendationListContainer } from './RecommendationSliderSection';

interface IBlogArticleRecommendationSectionProps {
  items: BlogArticleItemFragment[];
  title: string;
  id?: string;
}

const DESKTOP_ITEMS_COUNT = 3;

function BlogArticleRecommendationsSection({
  items,
  title,
  id,
}: IBlogArticleRecommendationSectionProps): ReactElement {
  const [imageHeight, setImageHeight] = useState<number | undefined>();
  const { mobile } = useDeviceType();
  const count = items.length;

  const updateImageHeight = useCallback((height: number) => {
    setImageHeight(height);
  }, []);

  if (!count) return null;

  return (
    <>
      <RecommendationListContainer id={id}>
        <BlogArticleRecommendationSliderSection
          title={title}
          count={count}
          itemsPerPage={DESKTOP_ITEMS_COUNT}
          isSliderPagination={!mobile}
          imageHeight={imageHeight}
          showHeader={false}
        >
          {items.map(function (item, index) {
            const route = getRouterLink(item.url);

            return (
              <RouterLink
                key={`blog-article-${index}`}
                {...(route || undefined)}
                passHref
              >
                <BlogArticleLink>
                  <BlogArticleItem
                    {...item}
                    onImageHeightChange={updateImageHeight}
                  />
                </BlogArticleLink>
              </RouterLink>
            );
          })}
        </BlogArticleRecommendationSliderSection>
      </RecommendationListContainer>
    </>
  );
}

export default BlogArticleRecommendationsSection;
