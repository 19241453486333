import styled, { CSSObject } from 'styled-components';
import { Link } from '@hotelplan/components.common.link';
import { cutTextByLinesCount } from '@hotelplan/components.common.mixins';
import { BsTeaser } from '@hotelplan/core.fusion.bs-teaser';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { TeaserImageCard } from 'components/domain/teaser-image-card';
import { RecommendationSliderSection } from './RecommendationSliderSection';

export const BlogArticleLink = styled(Link)({
  '&:hover': {
    color: 'inherit',
  },
});

export const BlogArticleRecommendationSliderSection = styled(
  RecommendationSliderSection
)({
  a: {
    '&:focus-visible, &:hover': {
      '.blog-article-main': {
        '.icon': {
          transform: 'translateX(0)',
        },
      },
    },
  },
});

export const BlogArticleInfoWrapper = styled(TeaserImageCard)(
  ({ theme: { colors, space, fontSizes } }) => ({
    position: 'absolute',
    top: '20px',
    left: 0,
    background: colors.goldLight,
    maxWidth: '260px',
    '.teaser-media': {
      display: 'flex',
      gap: space[3],
      padding: `20px ${space[4]}`,
      height: 'auto',
      img: {
        width: '60px',
        borderRadius: '100%',
        position: 'relative',
        height: '60px',
        transform: 'none !important',
        flexShrink: 0,
      },
      '.media-card-content': {
        position: 'relative',
        fontSize: fontSizes[1],
        padding: 0,
        color: colors.black,
        flexDirection: 'column',
      },
      '&:before, &:after': {
        content: 'none',
      },
    },
  })
);

const teaserMediaGradient: CSSObject = {
  ':before': {
    backgroundImage:
      'linear-gradient(-180deg, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.5) 100%)',
  },
};

export const BLogArticleContentWrapper = styled.article({
  '.hero-image': {
    '.teaser-media': {
      maxHeight: '640px',
      color: '#FFFFFF',
      ...teaserMediaGradient,
    },
  },
});

export const BlogArticleContent = styled.div(
  ({ theme: { colors, space, text, fontSizes } }) => ({
    width: '100%',
    height: '100%',
    '.author-image': {
      '.teaser-media': {
        color: '#FFFFFF',
        ...teaserMediaGradient,
      },
    },
    '.heading-wrap': {
      marginTop: space[6],
      '.icon': {
        color: colors.primary,
        marginLeft: space[2],
        width: '23px',
        verticalAlign: 'text-bottom',
      },
    },
    '.heading-title': {
      fontSize: '22px',
      display: 'inline',
      p: {
        display: 'inline',
      },
    },
    '.lead-text': {
      marginTop: space[2],
      fontSize: fontSizes[1],
      ...cutTextByLinesCount(5),
      maxHeight: '145px',
    },
    '.blog-article-tags': {
      marginTop: space[3],
      ...text.groupHeading,
      '.blog-article-tag': {
        '&:after': {
          display: 'inline-block',
          content: '"\\000a0-\\000a0"',
        },
        '&:last-child:after': {
          display: 'none',
        },
      },
    },
    '.icon': {
      transition: 'all 0.5s ease',
      transform: 'translateX(-10px)',
    },
  })
);

export const BlogArticleDescription = styled.div({
  display: 'flex',
});

export const BlogArticleChevronWrapper = styled.div(
  sx2CssThemeFn({
    alignSelf: 'flex-end',
    pl: '10px',
    mb: '-4px',
    flexShrink: 0,
    '.icon': {
      width: '24px',
      height: '24px',
      color: 'redWeb',
    },
  })
);

export const BLogArticleMediaTeaser = styled(BsTeaser)(
  sx2CssThemeFn({
    '.teaser-media:after': {
      paddingBottom: ['93%', '83%'],
    },
  })
);
