import React, { ReactElement } from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import { Image } from '@hotelplan/components.common.image';
import {
  Image as ImageType,
  InternalLink,
} from '@hotelplan/platform-graphql-api';

interface IMapCardInfoProps {
  image: ImageType;
  link?: InternalLink;
  iconDescription: string;
  title?: string;
  iconName: string;
}

const MapCardInfo = ({
  image,
  title,
  iconName,
  link,
  iconDescription,
}: IMapCardInfoProps): ReactElement => {
  return (
    <>
      <div className="infoWindow-image">{image && <Image {...image} />}</div>
      <div style={{ display: 'flex' }}>
        <div className="infoWindow-name">
          <div className="infoWindow-text-wrapper">
            <span className="infoWindow-text">{title || `-`}</span>
            {link && (
              <span className="arrow-icon">
                <Icon name="chevron-long-right" />
              </span>
            )}
          </div>
        </div>
        {link && (
          <div className="open-icon">
            <Icon name={iconName} />
            <span>{iconDescription}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default MapCardInfo;
