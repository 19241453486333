import React, { PropsWithChildren, ReactElement } from 'react';
import { GeoProduct, ProductFilter } from '@hotelplan/platform-graphql-api';
import CommonRecommendationSection from 'components/domain/recommendations/CommonRecommendationSection';
import { GeoModulesFragment } from 'graphql/geo/GetGeoModules.generated';
import { StaticProductRecommendationFragment } from 'graphql/recommendations/StaticProductRecommendation.generated';
import CommonGridRecommendationSection from './CommonGridRecommendationSection';
import { ProductRecommendation } from './ProductRecommendation';
import ProductRecommendationCustomContent from './ProductRecommendationsSectionCustomContent';
import {
  PRODUCT_GRID_ITEMS_PER_PAGE,
  ProductRecommendationView,
} from './Recommendations.constants';

type TProductRecommendationItem =
  | GeoProduct
  | GeoModulesFragment['products'][number];

const ProductRecommendationGridItem: React.FC<{
  item: TProductRecommendationItem;
}> = ({ item }) => {
  //TODO: Currently there's a bug in the backend and bookmarked property is not delivered for product recommendation on geo and theme geo pages
  return (
    <ProductRecommendation key={item.link.uri} {...item} bookmarked={false} />
  );
};

interface IComponentData
  extends Pick<
    StaticProductRecommendationFragment,
    'backgroundColor' | 'fontColor' | 'name'
  > {
  items: TProductRecommendationItem[];
  productFilters?: ProductFilter[];
}

interface ISectionProps {
  component: StaticProductRecommendationFragment | IComponentData;
  id?: string;
  loadMoreText?: string;
  view?: ProductRecommendationView;
  testId?: string;
  sectionHeaderTitle?: string;
}

export function ProductRecommendationsSection(
  props: PropsWithChildren<ISectionProps>
): ReactElement {
  const {
    component,
    id,
    loadMoreText,
    view = ProductRecommendationView.SLIDER,
    testId,
    children,
    sectionHeaderTitle,
  } = props;

  if (view == ProductRecommendationView.SLIDER) {
    return (
      <CommonRecommendationSection
        component={component}
        testId={testId}
        id={id}
        customContent={
          <ProductRecommendationCustomContent
            productFilters={component.productFilters}
          >
            {children}
          </ProductRecommendationCustomContent>
        }
        sectionHeaderTitle={sectionHeaderTitle}
      >
        {setImageHeight => {
          return component.items.map((item, index) => (
            <ProductRecommendation
              key={index}
              {...item}
              lazy={index + 1 > 3}
              getImageHeight={
                index === 0
                  ? height => {
                      if (!height) return;
                      setImageHeight(height);
                    }
                  : undefined
              }
            />
          ));
        }}
      </CommonRecommendationSection>
    );
  }

  return (
    <CommonGridRecommendationSection<TProductRecommendationItem>
      items={component.items as TProductRecommendationItem[]}
      recommendationItem={ProductRecommendationGridItem}
      perPage={PRODUCT_GRID_ITEMS_PER_PAGE}
      id={id}
      mainTitle={component.name}
      loadMoreText={loadMoreText}
      testId={testId}
    >
      {children}
    </CommonGridRecommendationSection>
  );
}
