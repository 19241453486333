import omit from 'lodash/fp/omit';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { ProductFilter } from '@hotelplan/platform-graphql-api';
import { CardsSection } from 'components/domain/customizable-slider/CardsSection';
import usePaginationVariant from 'components/domain/customizable-slider/usePaginationVariant';
import { TeaserImageCard } from 'components/domain/teaser-image-card';
import {
  StaticThemeRecommendationFragment,
  StaticThemeRecommendationItemFragment,
} from 'graphql/recommendations/StaticThemeRecommendation.generated';
import Filters from './Filters';
import { ListContainer, RecommendationsGrid } from './Recommendations.common';
import { ThemeRecommendationSliderSection } from './ThemeRecommendationSliderSection';

export interface ISectionProps
  extends Omit<StaticThemeRecommendationFragment, 'items'>,
    HTMLAttributes<HTMLDivElement> {
  items: Array<
    Omit<StaticThemeRecommendationItemFragment, 'id'> & { id?: string }
  >;
  testId?: string;
  productFilters?: ProductFilter[];
}

const ThemeListContainer = styled(ListContainer)<{
  isSlides: boolean;
  titleColor?: string;
}>(({ isSlides, theme: { colors } }) => ({
  '@media screen and (max-width: 1023px)': {
    paddingRight: isSlides && 0,
  },
  '.pages': {
    color: colors.white,
  },
}));

const ThemeListWithSlidesNavigationContainer = styled(ListContainer)<{
  isSlides: boolean;
  titleColor?: string;
}>(({ isSlides, titleColor, theme: { colors } }) => ({
  '@media screen and (max-width: 1023px)': {
    paddingRight: isSlides && 0,
  },
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '20px',
  paddingRight: '40px',
  paddingLeft: '40px',
  '.count': {
    color: titleColor || colors.white,
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '20px',
  },
}));

export function ThemeRecommendationsSection({
  displayType = 'SWIPE',
  name,
  items,
  strictBackgroundColor: backgroundColor,
  strictFontColor: color,
  testId,
  productFilters = [],
  ...divProps
}: ISectionProps): React.ReactElement {
  const isSlides = displayType === 'SWIPE';
  const isSliderPagination = usePaginationVariant();

  const ThemeListContainerVariant = isSliderPagination
    ? ThemeListWithSlidesNavigationContainer
    : ThemeListContainer;

  const itemsResult = items.map((item, index) => (
    <TeaserImageCard
      key={item.id || index}
      title={item.name}
      image={item.image}
      link={item.link}
      lazy={isSlides && index > 2}
      data-id="theme-card"
    />
  ));

  const customContent = productFilters.length ? (
    <Filters items={productFilters} />
  ) : null;

  return (
    <div
      className="themes-section"
      data-id={testId}
      {...omit(`__typename`)(divProps)}
      style={{ ...(divProps.style || {}), color, backgroundColor }}
    >
      <ThemeListContainerVariant
        data-display={displayType}
        isSlides={isSlides}
        titleColor={color}
      >
        {!isSlides ? (
          <CardsSection title={name} page={1} totalPages={1}>
            <RecommendationsGrid style={{ borderColor: color }}>
              {itemsResult}
            </RecommendationsGrid>
          </CardsSection>
        ) : (
          <ThemeRecommendationSliderSection
            title={name}
            count={items.length}
            titleColor={color}
            customContent={customContent}
          >
            {itemsResult}
          </ThemeRecommendationSliderSection>
        )}
      </ThemeListContainerVariant>
    </div>
  );
}
