import React, { useMemo } from 'react';
import styled from 'styled-components';
import type { INormalizedImage } from '@hotelplan/components.common.image';
import {
  IJustCarouselProps,
  JustCarousel,
} from '@hotelplan/components.common.just-carousel';
import {
  BsVideo,
  DEFAULT_VIDEO_ASPECT_RATIO,
} from '@hotelplan/core.fusion.bs-video';
import ImgWithCopyright from 'components/domain/copyright/img-with-copyright';
import ResponsiveImgWithCopyright from 'components/domain/copyright/responsive-img-with-copyright';
import { heroImagesSpecs } from 'components/domain/criterias';

export type ImageFragment = {
  __typename?: 'ImageMediaItem';
  title: string;
  image: INormalizedImage;
};

export type VideoFragment = {
  __typename?: 'VideoMediaItem';
  title: string;
  resource: string;
  copyright?: string;
  preview: INormalizedImage;
  ratio?: number;
};

interface IMediaGalleryStyles {
  flagline?: string;
  mainTitle?: string;
  fontColor?: string;
}

interface IPlayerProps {
  muted?: boolean;
  loop?: boolean;
  fullScreen?: boolean;
}

type IHeroMediaGalleryProps = Omit<
  IJustCarouselProps<ImageFragment | VideoFragment>,
  'renderItem'
> &
  IMediaGalleryStyles &
  IPlayerProps;

const HeroCarousel = styled(JustCarousel)(({ theme }) => ({
  ...theme.hero.wrap,
  '.image-media': theme.hero.wrap,
})) as React.FC<IJustCarouselProps<ImageFragment | VideoFragment>>;

const ImageMediaWrapper = styled.div({
  width: '100%',
  height: '100%',
  position: 'relative',
  '&:after': {
    position: 'absolute',
    content: '""',
    left: 0,
    top: 0,
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%)',
    width: '100%',
    height: '100%',
    opacity: '.5',
    zIndex: 1,
  },
});

const HeroMediaGalleryWrapper = styled.div({
  position: 'relative',
});

const GalleryOverlayContent = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 2,
  textShadow: '1px 1px 1px rgba(0,0,0,0.3)',
});

export const MediaImage = styled(ImgWithCopyright)({
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  alignSelf: 'center',
  objectFit: 'cover',
});

const PreviewImage = styled(MediaImage)({
  position: 'absolute',
  zIndex: 9999,
});

const render = (fullScreen, loop, muted) =>
  function Switch(item, index, { visible }, auto) {
    switch (item.__typename) {
      case 'ImageMediaItem':
        return (
          <ImageMediaWrapper className="hero-media-wrapper" key={index}>
            <ResponsiveImgWithCopyright
              specs={heroImagesSpecs}
              image={item.image}
              ImageElement={MediaImage}
              imageProps={{ lazy: index !== 0, className: 'image-media' }}
            />
          </ImageMediaWrapper>
        );
      case 'VideoMediaItem':
        return (
          <BsVideo
            fullScreen={fullScreen}
            key={`video-${index}`}
            active={visible}
            preview={item.preview}
            resource={item.resource}
            copyright={item.copyright}
            lazy={index !== 0}
            onPlay={auto.stop}
            onEnded={auto.trigger}
            loop={loop}
            muted={muted}
            ratio={item.ratio || DEFAULT_VIDEO_ASPECT_RATIO}
            previewComponent={(started: boolean) => (
              <ResponsiveImgWithCopyright
                specs={heroImagesSpecs}
                image={item.preview}
                ImageElement={PreviewImage}
                imageProps={{
                  lazy: true,
                  style: { zIndex: started ? 0 : 9999 },
                }}
              />
            )}
          />
        );
      default:
        return null;
    }
  };

export const HeroMediaGallery: React.FC<IHeroMediaGalleryProps> = props => {
  const {
    items,
    ratio = 0.5,
    sliding = false,
    chevrons = false,
    autoSlide = 7000,
    children,
    loop = false,
    muted = false,
    fullScreen = false,
    ...carouselProps
  } = props;

  const renderItem = useMemo(() => render(fullScreen, loop, muted), [
    fullScreen,
    loop,
    muted,
  ]);

  return (
    <HeroMediaGalleryWrapper>
      <HeroCarousel
        sliding={sliding}
        ratio={ratio}
        chevrons={chevrons}
        autoSlide={autoSlide}
        {...carouselProps}
        items={items.filter(item =>
          ['ImageMediaItem', 'VideoMediaItem'].includes(item.__typename)
        )}
        renderItem={renderItem}
      />
      {children ? (
        <GalleryOverlayContent>{children}</GalleryOverlayContent>
      ) : null}
    </HeroMediaGalleryWrapper>
  );
};
