import noop from 'lodash/fp/noop';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { Link } from '@hotelplan/components.common.link';
import { InternalLink } from '@hotelplan/platform-graphql-api';
import MapCardInfo from 'components/domain/map/MapCardInfo';
import { TResultProductWithRoute } from './Routes.types';

type TLinkBoxProps = {
  link?: InternalLink;
  onHoverToggle?(): void;
};
type TCustomInfoWindowProps = {
  selected: TResultProductWithRoute;
};

const LinkBox: React.FC<React.PropsWithChildren<TLinkBoxProps>> = ({
  children,
  link,
  onHoverToggle = noop,
}) => {
  if (link && link?.uri)
    return (
      <Link
        href={link.uri}
        target="_blank"
        onMouseOver={() => onHoverToggle(true)}
        onMouseOut={() => onHoverToggle(false)}
        className="infoWindow-link-box"
      >
        {children}
      </Link>
    );

  return <div>{children}</div>;
};

export const CustomInfoWindow: React.FC<TCustomInfoWindowProps> = ({
  selected,
}) => {
  const [t] = useTranslation('common');
  const image = selected.images[0]?.preview || selected?.image;

  return (
    <LinkBox link={selected.link}>
      <MapCardInfo
        link={selected.link}
        image={image}
        title={selected.name}
        iconName="route-adventuretravel-icon"
        iconDescription={t('continueTrip')}
      />
    </LinkBox>
  );
};
