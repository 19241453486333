import { useGoogleMap } from '@react-google-maps/api';
import { isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { mapCoordinatesToLatLng } from '@hotelplan/components.common.map-pin';
import { IProductPinItem } from './Pins.types';
import ProductPin from './ProductPin';

const ProductMapPins: React.FC<{
  items: IProductPinItem[];
  visible: boolean;
  selectItem?(group: IProductPinItem): void;
  selectedItem?: IProductPinItem;
  showDetails?: boolean;
}> = ({ items, selectItem, selectedItem, showDetails, visible }) => {
  const map = useGoogleMap();

  useEffect(() => {
    if (!map || !items) return;

    const bounds = new google.maps.LatLngBounds();

    items.forEach(item => {
      bounds.extend(mapCoordinatesToLatLng(item.coordinates));
    });

    requestAnimationFrame(() => {
      map.fitBounds(bounds, 30);
    });
  }, [map, items]);

  function onPinClick(item: IProductPinItem) {
    selectItem && selectItem(item);
  }

  return (
    <>
      {items.map((item, index) => {
        return (
          <ProductPin
            key={`product-pin-${index}`}
            index={index}
            item={item}
            visible={visible}
            selected={!!selectedItem && isEqual(selectedItem, item)}
            onClick={onPinClick}
            showDetails={showDetails}
          />
        );
      })}
    </>
  );
};

export default ProductMapPins;
