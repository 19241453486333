import React, { ReactElement, useEffect, useRef } from 'react';
import { ThirdHeading } from 'theme/headings';
import { Icon } from '@hotelplan/components.common.icon';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import ResponsiveImgWithCopyright from 'components/domain/copyright/responsive-img-with-copyright';
import { blogSpecs } from 'components/domain/criterias';
import { TeaserImageCard } from 'components/domain/teaser-image-card';
import { BlogArticleItemFragment } from 'graphql/blogArticle/BlogArticleItem.generated';
import {
  BLogArticleContentWrapper,
  BLogArticleMediaTeaser,
  BlogArticleInfoWrapper,
  BlogArticleChevronWrapper,
  BlogArticleDescription,
  BlogArticleContent,
} from './BlogArticle.styles';
import BlogArticleInfo from './BlogArticleInfo';

interface IBlogArticleItem extends BlogArticleItemFragment {
  onImageHeightChange?: (height: number) => void;
}

function BlogArticleItem({
  hero,
  author,
  mainTitle,
  leadText,
  tags,
  date,
  onImageHeightChange,
}: IBlogArticleItem): ReactElement {
  const imageRef = useRef<HTMLDivElement>(null);
  const { h: imageHeight } = useElementSize(imageRef);

  useEffect(() => {
    onImageHeightChange && onImageHeightChange(imageHeight);
  }, [imageHeight, onImageHeightChange]);

  return (
    <BLogArticleContentWrapper>
      <BlogArticleContent className="blog-article-main">
        <div ref={imageRef}>
          {hero ? (
            <>
              <BLogArticleMediaTeaser
                icon={
                  <Icon name="chevron-long-left" className="chevron-icon" />
                }
                image={
                  hero && (
                    <ResponsiveImgWithCopyright
                      image={hero}
                      specs={blogSpecs}
                    />
                  )
                }
                className="hero-image"
              />
              <BlogArticleInfoWrapper
                image={author.image}
                className="author-image"
              >
                <BlogArticleInfo name={author.name} date={date} />
              </BlogArticleInfoWrapper>
            </>
          ) : (
            <TeaserImageCard image={author.image} className="author-image">
              <BlogArticleInfo name={author.name} date={date} />
            </TeaserImageCard>
          )}
        </div>
        <BlogArticleDescription>
          <div>
            <div className="heading-wrap">
              <ThirdHeading className="heading-title">{mainTitle}</ThirdHeading>
            </div>
            <div
              className="lead-text"
              dangerouslySetInnerHTML={{ __html: leadText }}
            />
          </div>
          <BlogArticleChevronWrapper>
            <Icon name={'chevron-long-right'} />
          </BlogArticleChevronWrapper>
        </BlogArticleDescription>
        {tags?.length > 0 && (
          <div className="blog-article-tags">
            {tags.map((tag, index) => {
              return (
                <span
                  className={'blog-article-tag'}
                  key={`blog-article-tag-${index}`}
                >{`${tag}`}</span>
              );
            })}
          </div>
        )}
      </BlogArticleContent>
    </BLogArticleContentWrapper>
  );
}

export default BlogArticleItem;
