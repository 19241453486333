import React from 'react';
import styled from 'styled-components';
import { SecondaryHeading } from 'theme/headings';
import { USPBox } from '@hotelplan/components.common.usp-box';
import { Container } from 'components/domain/container';
import { UspFragment } from 'graphql/fragments/Usp.generated';

interface IUSPSection {
  title?: string;
  fontColor?: string;
  bgColor?: string;
  boxes: UspFragment[];
}

const UspContainerWrapper = styled.div<{
  fontColor?: string;
  bgColor?: string;
}>(({ fontColor, bgColor, theme: { space } }) => ({
  backgroundColor: bgColor,
  color: fontColor,
  padding: `${space[8]} 0`,
}));

const UspSectionWrapper = styled.div(({ theme: { space } }) => ({
  display: 'flex',
  flexDirection: 'column',
  '.boxes-title': {
    marginBottom: space[4],
  },
}));

const UspBoxes = styled.div(({ theme: { media, space, colors } }) => ({
  display: 'flex',
  overflowX: 'auto',
  paddingRight: `${space[4]}`,
  [media.tablet]: {
    paddingRight: '0px',
  },
  '.with-link': {
    '&:hover': {
      borderColor: colors.primary,
    },
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
}));

const UspSeparateLine = styled.hr(({ theme: { colors } }) => ({
  width: '100%',
  border: 'none',
  borderBottom: `1px solid ${colors.darkGrey}`,
  marginTop: '20px',
}));

const UspSection: React.FC<IUSPSection> = ({
  title,
  boxes,
  fontColor,
  bgColor,
}) => {
  return (
    <UspContainerWrapper fontColor={fontColor} bgColor={bgColor}>
      <Container>
        <UspSectionWrapper>
          {title ? (
            <SecondaryHeading className="boxes-title">{title}</SecondaryHeading>
          ) : null}
          <UspBoxes>
            {boxes?.map((box, i) => {
              return (
                <USPBox
                  key={i}
                  title={box.title}
                  subTitle={box.subtitle}
                  text={box.text}
                  link={box.link}
                  icon={box.icon}
                >
                  <UspSeparateLine />
                </USPBox>
              );
            })}
          </UspBoxes>
        </UspSectionWrapper>
      </Container>
    </UspContainerWrapper>
  );
};

export default UspSection;
