import React, { useContext } from 'react';
import { RouteContext } from 'components/domain/adventure-routing/Routes.context';
import { IProductPinItem } from './Pins.types';
import ProductMapPins from './ProductMapPins';

type TProps = {
  mapSelectGroupHandler?(nextItem: IProductPinItem): void;
  selectedItem?: IProductPinItem;
};

export const Pins: React.FC<TProps> = ({
  mapSelectGroupHandler,
  selectedItem,
}) => {
  const { isRouteMode, products, hovered, active, hasCurrent } = useContext(
    RouteContext
  );

  const list = isRouteMode
    ? products.map(i => i.routePoints)
    : products.map(i => i.images);

  return (
    <>
      {list.map((items, key) => {
        return (
          <ProductMapPins
            key={key}
            items={items}
            visible={
              list.length === 1 ||
              active === key ||
              hovered === key ||
              (hasCurrent && !hovered && !active && key === 0)
            }
            selectItem={mapSelectGroupHandler}
            selectedItem={selectedItem}
            showDetails={isRouteMode && hovered === key}
          />
        );
      })}
    </>
  );
};
