import React from 'react';
import BlogDate from 'components/domain/blog-overview/BlogDate';

interface IBlogArticleInfo {
  name: string;
  date?: string;
}

const BlogArticleInfo: React.FC<IBlogArticleInfo> = ({ name, date }) => {
  return (
    <>
      <address>{name}</address>
      {date && <BlogDate date={date} />}
    </>
  );
};

export default BlogArticleInfo;
