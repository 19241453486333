import styled from 'styled-components';
import { BaseInput } from '@hotelplan/components.common.inputs';
import { TextBlock } from '@hotelplan/components.common.text-block';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Container } from 'components/domain/container';

export const HeroMediaContent = styled(Container)(
  sx2CssThemeFn({
    position: 'absolute',
    top: ['220px', '191px'],
    margin: '0 auto',
    left: '0',
    right: '0',
    width: '100%',
    maxWidth: ['none', '1480px'],
    p: [0, '0 20px'],
    '&.is-home': {
      top: ['40%', '50%'],
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: 'none',
      mt: 0,
      ml: 0,
    },
    '.site-tools-button-wrapper': {
      '.icon': {
        width: '27px',
        height: '27px',
        color: 'white',
        fill: 'white',
        ml: 0,
        marginTop: '0',
        transform: 'none',
      },
      button: {
        ':hover': {
          border: 'none',
          '.icon': {
            color: 'white',
          },
        },
      },
    },
    '&.not-home': {
      '.site-tools-button-wrapper, .site-tools-button-wrapper-bookmark': {
        position: 'relative',
        width: '42px',
        height: '42px',
        border: '1px solid white',
        borderRadius: '5px',
        '> .icon, > button > .icon ': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '20px',
          height: '20px',
          color: 'white',
          fill: 'transparent',
          margin: '0',
        },
        '.button-text': {
          position: 'absolute',
          top: '53px',
          width: 'max-content',
          textShadow: 'none',
          '&:first-letter': {
            textTransform: `capitalize`,
          },
        },
        '&:hover': {
          backgroundColor: 'paperGrey',
          borderColor: 'redHover',
          '> .icon, > button > .icon': {
            color: 'redHover',
          },
          '.button-text': {
            display: 'block',
            color: 'black',
            fontWeight: '400',
            fontSize: 1,
            lineHeight: '22px',
            letterSpacing: '0.5px',
            backgroundColor: 'paperGrey',
            boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
            padding: '5px 10px',
            left: ['auto', '50%'],
            right: [0, 'none'],
            transform: ['none', `translateX(-50%)`],
            zIndex: '110',
            '&:before': {
              content: '""',
              position: 'absolute',
              width: '16px',
              height: '16px',
              left: ['auto', '50%'],
              right: ['4px', 'none'],
              top: '-8px',
              backgroundColor: 'paperGrey',
              transform: 'translateX(-50%)  rotate(-45deg)',
              zIndex: '1',
            },
          },
        },
        '&:active': {
          backgroundColor: 'white',
          borderColor: 'redActive',
          '> .icon, > button > .icon': {
            color: 'redActive',
          },
        },
      },
      '.site-tools-button-wrapper-bookmark > button': {
        width: '100%',
        height: '100%',
      },
    },
  })
);

export const SearchInput = styled(BaseInput)(
  ({ theme }) => theme.inputs.searchInput,
  ({ theme: { media, colors } }) => ({
    width: '100%',
    maxWidth: '912px',
    margin: '0 auto',
    zIndex: 2,
    [media.mobile]: {
      width: '93%',
    },
    '.input-icon': {
      color: colors.primary,
      fill: 'transparent',
      left: '17px',
      top: '50%',
      transform: 'translateY(-50%)',
      marginLeft: 0,
      [media.mobile]: {
        left: '12px',
        width: '24px',
      },
    },
  })
);

const DefaultHeroTextBlock = styled(TextBlock)<{ color?: string }>(
  sx2CssThemeFn(({ color, theme: { text } }) => ({
    color,
    zIndex: '10',
    variant: 'richText',
    display: 'flex',
    flexDirection: 'column-reverse',
    h1: {
      fontSize: ['42px', '65px'],
      lineHeight: ['48px', '75px'],
      fontWeight: '800',
      letterSpacing: [`unset`, '1px'],
    },
    '.text-block-text': {
      ...text.groupHeading,
    },
  }))
);

export const HeroTextBlock = styled(DefaultHeroTextBlock)(
  sx2CssThemeFn(({ theme: { space } }) => ({
    maxWidth: ['100%', '1440px'],
    textAlign: 'left',
    padding: [`0 ${space[3]}`, '0'],
    marginTop: ['60px', '100px'],
    textShadow: 'none',
    h1: {
      maxWidth: ['100%', '60%'],
    },
    '.text-block-text': {
      mb: 3,
      maxWidth: ['100%', '50%'],
      fontSize: '15px',
    },
  }))
);

export const HeroTextBlockHome = styled(DefaultHeroTextBlock)(
  sx2CssThemeFn({
    width: ['93%', '95%'],
    maxWidth: ['460px', '100%'],
    textAlign: 'center',
    margin: '0 auto',
    h1: {
      maxWidth: ['100%', 'none'],
      textShadow: 'none',
    },
    '.text-block-text': {
      mb: [2, 3],
    },
  })
);

export const HeroTextBlockGeo = styled(HeroTextBlockHome)(
  sx2CssThemeFn({
    textAlign: [`left`, 'center'],
    marginTop: ['60px', '100px'],
  })
);
