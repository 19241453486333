import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { BsTeaser } from '@hotelplan/core.fusion.bs-teaser';
import { BsMedias } from '@hotelplan/core.serving.bs-medias';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import ResponsiveImgWithCopyright from 'components/domain/copyright/responsive-img-with-copyright';
import { marketingImagesSpecs } from 'components/domain/criterias/marketingRecommendation.dmc';
import { CardsSection } from 'components/domain/customizable-slider/CardsSection';
import { TeaserImageCard } from 'components/domain/teaser-image-card';
import { MarketingRecommendationFragment } from 'graphql/recommendations/MarketingRecommendation.generated';
import { ListContainer, RecommendationsGrid } from './Recommendations.common';
import { RecommendationSliderSection } from './RecommendationSliderSection';

const ITEMS_PER_PAGE = 3;

interface ISectionProps extends MarketingRecommendationFragment {
  className?: string;
  testId?: string;
}

const SingleMarketingItem = styled(BsTeaser)(
  sx2CssThemeFn({
    '&:hover': {
      '.teaser-media img': {
        transform: 'scale(1.0)',
      },
    },
    a: {
      '&:focus-visible': {
        '&:before': {
          content: '',
          outline: '1px solid',
          outlineOffset: '0px',
        },
        img: {
          transform: 'scale(1.0)',
          transition: 'all 0.5s ease',
        },
      },
    },
    '.teaser-media:after': {
      paddingBottom: ['70%', '38%'],
    },
    '.media-card-content': {
      maxWidth: '1480px',
      paddingLeft: [3, '20px'],
      paddingRight: [3, '20px'],
      left: '50%',
      transform: 'translateX(-50%)',
      paddingBottom: '30px',
    },
  })
);

const MediaCardMedia = styled(BsMedias)({
  height: '100%',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

export function MarketingRecommendationsSection({
  items,
  backgroundColor,
  fontColor,
  titleFontColor,
  name,
  className,
  testId,
  displayType = 'SWIPE',
}: ISectionProps): ReactElement {
  const count = items.length;
  const first = items[0];

  const { fullScreen, ...preview } = first?.preview || {};
  const firstFullPreview = {
    ...preview,
    image: fullScreen,
    preview: fullScreen,
  };

  const itemsResult = items.map((item, index) => (
    <TeaserImageCard
      key={index}
      title={item.title}
      subTitle={item.subtitle}
      link={item.link}
      media={item.preview}
      lazy={displayType === 'SWIPE' && index > 2}
      data-id="marketing-card"
      className="marketing-item"
    />
  ));

  return (
    <div
      className={className}
      style={{
        color: fontColor,
        backgroundColor,
      }}
      data-id={testId}
    >
      {count === 1 ? (
        <SingleMarketingItem
          title={first.title}
          subTitle={first.subtitle}
          icon={<Icon name="chevron-long-left" className="chevron-icon" />}
          image={
            firstFullPreview && (
              <MediaCardMedia {...firstFullPreview} lazy={false}>
                <ResponsiveImgWithCopyright
                  image={firstFullPreview.image}
                  specs={marketingImagesSpecs}
                />
              </MediaCardMedia>
            )
          }
          link={first.link}
        />
      ) : (
        <ListContainer data-display={displayType}>
          {displayType === 'BLOC' ? (
            <CardsSection
              title={name}
              page={1}
              totalPages={1}
              headerStyle={{ color: titleFontColor, marginRight: '24px' }}
            >
              <RecommendationsGrid style={{ borderColor: titleFontColor }}>
                {itemsResult}
              </RecommendationsGrid>
            </CardsSection>
          ) : (
            <RecommendationSliderSection
              title={name}
              count={count}
              titleColor={titleFontColor || fontColor}
              itemsPerPage={ITEMS_PER_PAGE}
            >
              {itemsResult}
            </RecommendationSliderSection>
          )}
        </ListContainer>
      )}
    </div>
  );
}
