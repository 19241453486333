import { useGoogleMap } from '@react-google-maps/api';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { mapCoordinatesToLatLng } from '@hotelplan/components.common.map-pin';
import { PageType } from '@hotelplan/graphql.types';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';
import { CustomInfoWindow } from 'components/domain/adventure-routing/CustomInfoWindow';
import { DoublePolyline } from 'components/domain/adventure-routing/DoublePolyline';
import { RouteContext } from 'components/domain/adventure-routing/Routes.context';
import MapInfoBlock from 'components/domain/map/MapInfoBlock';

interface IRoutePolylinesProps {
  defaultZoom?: number;
  isGeo?: boolean;
}

export const RoutePolylines: React.FC<IRoutePolylinesProps> = ({
  defaultZoom,
  isGeo = false,
}) => {
  const [latLng, setLatLng] = useState(null);
  const map = useGoogleMap();
  const pageType = usePageType();

  const {
    click,
    isRouteMode,
    products,
    active,
    showNearBy,
    hasCurrent,
  } = useContext(RouteContext);
  const list = useMemo(
    () => products?.map(i => i.routePoints)?.filter(i => i?.length > 0),
    [products]
  );

  useIsomorphicLayoutEffect(() => {
    if (!showNearBy && latLng) {
      setLatLng(null);
      click(null);
    }
  }, [showNearBy]);

  const requiredReturnConditions = !isRouteMode || !map;
  const additionReturnCondition =
    hasCurrent && (list.length > 1 || !list[0]) && !showNearBy;

  useEffect(() => {
    if (requiredReturnConditions || additionReturnCondition || !list[0]) return;

    const bounds = new google.maps.LatLngBounds();

    isGeo
      ? list.forEach(product => {
          product.forEach(route => {
            bounds.extend(mapCoordinatesToLatLng(route.coordinates));
          });
        })
      : list[0].forEach(route => {
          bounds.extend(mapCoordinatesToLatLng(route.coordinates));
        });

    requestAnimationFrame(() => {
      map.fitBounds(bounds, 30);
      if (isRouteMode && defaultZoom) {
        map.setZoom(defaultZoom);
      }
    });
  }, [requiredReturnConditions, showNearBy, additionReturnCondition]);

  if (!isRouteMode) return null;

  const selected = products[active];
  const content = selected ? <CustomInfoWindow selected={selected} /> : null;

  const handleInfoBlockClose = () => {
    setLatLng(null);
    click(undefined);
  };

  return (
    <>
      {list.map((items, key) => {
        return (
          <DoublePolyline
            key={key}
            keyCode={key}
            items={items}
            list={list}
            setLatLng={setLatLng}
          />
        );
      })}

      {latLng && showNearBy && (
        <MapInfoBlock
          latLng={latLng}
          onCloseClick={handleInfoBlockClose}
          isOpenedByRouteOnGeo={pageType === PageType.GeoObject}
        >
          {content}
        </MapInfoBlock>
      )}
    </>
  );
};
