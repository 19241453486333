import findIndex from 'lodash/findIndex';
import { useTranslation } from 'next-i18next';
import React, { useMemo, useRef, useState } from 'react';
import { FlaglineText } from 'theme/headings';
import {
  CustomMapControl,
  MapControlPosition,
  MapProvider,
} from '@hotelplan/components.common.map';
import {
  IGalleryApi,
  BsRsvGallery,
  OverlaySlideInfo,
  SlideInfo,
} from '@hotelplan/core.serving.bs-rsv-gallery';
import { GeoCoordinates, PageType } from '@hotelplan/graphql.types';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';
import { StaticProductRecommendationItem } from '@hotelplan/platform-graphql-api';
import { RoutePolylines } from 'components/domain/adventure-routing/RoutePolylines';
import { Routes } from 'components/domain/adventure-routing/Routes.context';
import {
  ProductPinType,
  TInputProductWithRoute,
} from 'components/domain/adventure-routing/Routes.types';
import { useAdventureRoutingEnabled } from 'components/domain/adventure-routing/useAdventureRoutingEnabled';
import { renderGalleryWithMap } from 'components/domain/criterias';
import { productMapOptions } from 'components/domain/map/Map.constants';
import { createFullScreenControl } from 'components/domain/map/Map.utils';
import { useFullScreenControl } from 'components/domain/map/useFullScreenControl';
import { Pins } from 'components/domain/product-map-pins/Pins';
import { IProductPinItem } from 'components/domain/product-map-pins/Pins.types';
import { trackMediaGalleryFullScreenClick } from 'components/domain/tracking/mediaGalleryTracking';
import {
  MapClickEventTargetType,
  trackMapClick,
} from 'components/domain/tracking/pdpTracking';
import { ClickEventActionType } from 'components/domain/tracking/tracking.types';
import {
  ProductGalleryMapContent,
  ProductGalleryMapWrapper,
  ProductGalleryWithMapContent,
  ProductGalleryWithMapWrapper,
  ProductMediaGalleryWrapper,
} from './ProductGalleryWithMap.styles';
import { nearbyCheckbox } from './ProductGalleryWithMap.utils';
import { createDefaultMapPinControl } from './useMapPinControl';

interface IProductGalleryWithMapProps {
  currentProduct: TInputProductWithRoute;
  overlayStatus?: boolean;
  products?: StaticProductRecommendationItem[];
  defaultPinType?: ProductPinType;
}

const PRODUCT_GALLERY_MAP_ZOOM = 12;

const ProductGalleryWithMap: React.FC<IProductGalleryWithMapProps> = ({
  currentProduct,
  products,
  overlayStatus,
  defaultPinType,
}) => {
  const [t] = useTranslation('common');
  const pageType = usePageType();
  const [showNearBy, setShowNearBy] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [defaultZoom, setDefaultZoom] = useState<number>(0);
  const mapRef = useRef<google.maps.Map>(null);
  const productGroupsMapRef = useRef<HTMLDivElement>(null);
  const { isFullScreen, setIsFullScreen } = useFullScreenControl(
    productGroupsMapRef
  );
  const mapSize = useElementSize(productGroupsMapRef, { enable: true });
  const [pinType, setPinType] = useState(
    defaultPinType ?? ProductPinType.PinIcon
  );
  const isNearByFeatureEnabled = useAdventureRoutingEnabled();
  const isRouteMode = pinType === ProductPinType.RouteIcon;
  const { images: imageItems, name: title, routePoints } = currentProduct;
  const isPdp = pageType === PageType.Pdp;

  useIsomorphicLayoutEffect(() => {
    if (!isFullScreen && showNearBy) {
      setShowNearBy(false);
    }

    if (mapRef.current && isFirstLoad) {
      setDefaultZoom(mapRef.current.getZoom());
      setIsFirstLoad(false);
    }
  }, [isFullScreen]);

  const allMediaItems = useMemo(
    function mapImageGroupsToMediaImages() {
      const result = [];
      imageItems.forEach(imageItem => {
        result.push({
          __typename: 'ImageMediaItem',
          image: imageItem.image,
          preview: imageItem.preview,
          title: imageItem.image.alt,
          coordinates: imageItem.coordinates,
        });
      });
      return result;
    },
    [imageItems]
  );

  const [selectedItem, setSelectedItem] = useState<IProductPinItem>(
    imageItems[0]
  );
  const galleryApi = useRef<IGalleryApi>(null);

  function mapSelectGroupHandler(nextItem: IProductPinItem): void {
    setSelectedItem(nextItem);

    if (!isRouteMode) {
      galleryApi.current?.setSlide(
        findIndex(allMediaItems, function (item) {
          return item.coordinates === nextItem.coordinates;
        })
      );
    }
  }

  function gallerySetItemHandler<T extends { coordinates: GeoCoordinates }>(
    nextItem: T
  ): void {
    if (!isRouteMode) {
      setSelectedItem(
        imageItems.find(i => i.coordinates === nextItem.coordinates)
      );
    }
  }

  React.useEffect(() => {
    if (!isFullScreen || !isPdp) return;
    trackMapClick(
      ClickEventActionType.ENLARGE,
      pinType === ProductPinType.PinIcon
        ? MapClickEventTargetType.FULL_SCREEN_MAP_IMAGE_VIEW
        : MapClickEventTargetType.FULL_SCREEN_MAP_ROUTING_VIEW
    );
  }, [isFullScreen]);

  if (!imageItems.length) return null;

  const productsWithRoutes = products?.filter(i => i.routePoints.length > 0);

  const isNearbyVisible =
    isNearByFeatureEnabled &&
    isRouteMode &&
    isFullScreen &&
    productsWithRoutes?.length;

  return (
    <ProductGalleryWithMapWrapper>
      <FlaglineText className="gallery-with-map-title">
        {title || t('galleryWithMap.title')}
      </FlaglineText>
      <ProductGalleryWithMapContent>
        <ProductGalleryMapWrapper ref={productGroupsMapRef}>
          <ProductGalleryMapContent>
            <MapProvider
              styles={{ width: `${mapSize.w}px`, height: `${mapSize.h}px` }}
              zoom={PRODUCT_GALLERY_MAP_ZOOM}
              options={productMapOptions}
              mapRef={mapRef}
            >
              <Routes
                productsWithRoutes={productsWithRoutes}
                currentProduct={currentProduct}
                pinType={pinType}
                showNearBy={showNearBy}
              >
                <Pins
                  selectedItem={selectedItem}
                  mapSelectGroupHandler={mapSelectGroupHandler}
                />
                <RoutePolylines defaultZoom={defaultZoom} />
              </Routes>

              {routePoints?.length && !isFullScreen && (
                <CustomMapControl
                  onCreateControl={() => {
                    return createDefaultMapPinControl({
                      pinType,
                      onChange: setPinType,
                      captionGallery: t('galleryWithMap.iconCaption.gallery'),
                      captionRouting: t('galleryWithMap.iconCaption.routing'),
                      isPdp,
                    });
                  }}
                  position={MapControlPosition.TOP_LEFT}
                />
              )}

              {isNearbyVisible && (
                <CustomMapControl
                  onCreateControl={nearbyCheckbox({
                    showNearBy,
                    onChange: setShowNearBy,
                    text:
                      productsWithRoutes.length === 1
                        ? t('showOneRouteNearBy')
                        : t('showRoutesNearBy'),
                    isPdp,
                  })}
                  position={MapControlPosition.TOP_RIGHT}
                />
              )}

              <CustomMapControl
                onCreateControl={createFullScreenControl({
                  onChange: setIsFullScreen,
                })}
                position={MapControlPosition.RIGHT_TOP}
              />
            </MapProvider>
          </ProductGalleryMapContent>
        </ProductGalleryMapWrapper>
        <ProductMediaGalleryWrapper>
          <BsRsvGallery
            renderSlide={renderGalleryWithMap}
            galleryApi={galleryApi}
            items={allMediaItems}
            setCurrentItem={gallerySetItemHandler}
            onChangeSlide={item => gallerySetItemHandler(item)}
            initialOverlayStatus={overlayStatus}
            className="highlighted-media-gallery"
            overlaySlideInfo={(item, slideIndex, count, isHideNavigation) => (
              <OverlaySlideInfo
                item={item}
                slide={slideIndex + 1}
                count={count}
                copyrightLabel={t('copyright.photo')}
                isHideNavigation={isHideNavigation}
              />
            )}
            slideInfo={(item, slideIndex, count, isHideNavigation) => (
              <SlideInfo
                item={item}
                slide={slideIndex + 1}
                count={count}
                copyrightLabel={t('copyright.photo')}
                isHideNavigation={isHideNavigation}
              />
            )}
            afterOverlayOpen={isPdp && trackMediaGalleryFullScreenClick}
          />
        </ProductMediaGalleryWrapper>
      </ProductGalleryWithMapContent>
    </ProductGalleryWithMapWrapper>
  );
};

export default ProductGalleryWithMap;
