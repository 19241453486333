import {
  GeoProduct,
  StaticProductRecommendationItem,
  Image,
  InternalLink,
  FullTextSearchItem,
} from '@hotelplan/platform-graphql-api';
import { ProductImageItemFragment } from 'graphql/fragments/ProductGalleryWithMap.generated';
import { GeoModulesFragment } from 'graphql/geo/GetGeoModules.generated';
import { RoutePoint } from 'graphql/types/resolversTypes';

export type TProduct =
  | GeoProduct
  | GeoModulesFragment['products'][number]
  | Omit<FullTextSearchItem, 'bookmarked' | 'mainText'>
  | Omit<StaticProductRecommendationItem, 'bookmarked'>;

export enum ProductPinType {
  PinIcon = 'PIN_ICON',
  RouteIcon = 'ROUTE_ICON',
}

export type TInputProductWithRoute = {
  name: string;
  images: ProductImageItemFragment[];
  routePoints?: RoutePoint[];
  link?: InternalLink;
};

export type TResultProductWithRoute = {
  name: string;
  images: ProductImageItemFragment[];
  routePoints?: RoutePoint[];
  link?: InternalLink;
  image?: Image;
};
